<template>
    <div class="home container" :class="$vuetify.breakpoint.xsOnly ? 'px-0' : ''">
        <div class="mt-12 pt-12" style="position: relative"></div>
        <background-parallax :backgroundOpacityProp="'0.6'" />

        <div v-if="!quoteRequest">
            <v-card class="smc-card-dialog" elevation="12">
                <v-card-title class="smc-card-dialog-title-inset primary white--text">
                    <span class="mx-auto">ESTIMATE</span>
                </v-card-title>
                <v-card-text>
                    <div class="my-6">
                        Please start capturing a
                        <v-btn
                            outlined
                            small
                            rounded
                            color="primary"
                            to="/"
                            class="px-2 mx-1"
                        >
                            request
                        </v-btn>
                        to get quote estimate.
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <div v-else>
            <v-card class="smc-card-dialog mb-12" elevation="12">
                <v-card-title class="smc-card-dialog-title-inset primary white--text">
                    <span class="mx-auto">VEHICLE</span>
                </v-card-title>
                <v-card-text>
                    <v-row class="mt-4">
                        <v-col sm="6" md="4">
                            <v-avatar class="ma-3" size="125" width="90%" tile>
                                <img :src="vehicle.imageUrl" class="smc-search-result-image" />
                            </v-avatar>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" style="text-align: left">
                            <strong>{{ vehicle.year }}</strong>
                            <br />
                            <strong>{{ vehicle.brand }}</strong>
                            <br />
                            <strong>{{ vehicle.range }}</strong>
                            <br />
                            <strong>{{ vehicle.model }}</strong>
                            <br />
                            <strong>{{ vehicle.fuelType }}</strong>
                            <br />
                            <strong>{{ vehicle.transmissionType }}</strong>
                            <br />
                            <strong>{{ vehicle.gearboxType }}</strong>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" style="text-align: left">
                            <strong>Capacity: </strong>{{ vehicle.capacity }}<br />
                            <strong>Aspiration: </strong>{{ vehicle.aspirationType }}<br />
                            <strong>Body: </strong>{{ vehicle.bodyType }}<br />
                            <strong>Drive: </strong>{{ vehicle.driveType }}<br />
                            <strong>Warrenty: </strong>{{ vehicle.warranty }}
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="smc-box box box-rounded box-transparent box-transparent-light" elevation="12">
                <v-card-text>
                    <v-sheet class="my-4" rounded v-if="loading">
                        <box-view-skeleton-loader class="mb-6" />
                        <box-view-skeleton-loader class="mb-6" />
                        <box-view-skeleton-loader class="mb-6" />
                    </v-sheet>
                    <div v-else>
                        <v-card
                            class="smc-card-dialog"
                            :class="index < estimates.length - 1 ? 'mb-12' : ''"
                            v-for="(item, index) in estimates"
                            :key="index"
                        >
                            <v-card-title class="smc-card-dialog-title-inset primary white--text">
                                <span class="mx-auto">{{ item.quoteRequestItemName }}</span>
                            </v-card-title>
                            <v-card-subtitle class="smc-card-dialog-subtitle-inset">
                                <span class="font-weight-medium"> Estimate </span>
                            </v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col class="d-flex" style="justify-content: center; flex-direction: column" cols="12" sm="6">
                                        <div>
                                            <label> Range: </label>
                                            <label class="primary--text font-weight-bold headline">
                                                {{ item.estimateFromPriceIncl | currency }}
                                            </label>
                                            <label class="body-2"> to </label>
                                            <label class="primary--text font-weight-bold headline">
                                                {{ item.estimateToPriceIncl | currency }}
                                            </label>
                                        </div>
                                        <div>
                                            Average:
                                            <label class="primary--text font-weight-bold headline">
                                                {{ item.estimateAveragePriceIncl | currency }}
                                            </label>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <vue-gauge
                                            :refid="`gauge_${index}`"
                                            :options="{
                                                chartWidth: 300,
                                                needleColor: '#ed3237',
                                                needleValue: itemAveragePercent(item),
                                                arcDelimiters: [
                                                    itemFromPercent(item),
                                                    itemAveragePercent(item),
                                                ],
                                                arcOverEffect: true,
                                                rangeLabel: [`R0`, `R${item.estimateToPriceIncl}`],
                                                centralLabel: `R${item.estimateAveragePriceIncl}`,
                                                arcColors: ['#c6c6c6', '#2196f3', '#2196f3'],
                                                arcLabels: [`R${item.estimateFromPriceIncl}`],
                                            }"
                                        />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div v-if="tooManyRequests">
                        <v-card class="smc-card-dialog">
                            <v-card-title class="smc-card-dialog-title-inset primary white--text">
                                <span class="mx-auto">Error 429</span>
                            </v-card-title>
                            <v-card-text>
                                <h2>Too Many Requests</h2>
                                <p class="mt-4">
                                    It looks like you've attempted to make too may requests.
                                </p>
                                <p>Please wait a minute before trying again.</p>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-card-text>
                <v-card-actions v-if="!loading && estimates.length > 0">
                    <v-spacer />
                    <v-btn @click="quoteDisclaimerShow = true" color="primary" class="smc-text-button smc-medium-button" rounded>
                        Request quotes
                    </v-btn>

                    <v-dialog v-model="quoteDisclaimerShow" content-class="smc-dialog" persistent>
                        <v-card class="smc-card-dialog">
                            <v-card-title class="primary white--text smc-card-dialog-title">
                                <span class="mx-auto"> Please note </span>
                            </v-card-title>
                            <div class="smc-card-top-bar">
                                <v-slide-x-transition>
                                    <car-flag class="smc-dialog-car-flag" :flagTextProp="'Hold on'" />
                                </v-slide-x-transition>
                            </div>
                            <v-card-subtitle class="title">
                                Please take note of the following
                            </v-card-subtitle>
                            <v-card-text>
                                <p>
                                    The following quote estimate is provided for informational purposes only and is not binding in any way.
                                    Actual costs may vary based on a variety of factors, and this estimate should not be relied upon as a
                                    guarantee of final pricing.
                                </p>
                                <p>
                                    This will initiate the process to recieve personalized quotes for the requested service or repairs from our
                                    workshop network.
                                </p>
                            </v-card-text>
                            <v-divider />
                            <v-card-actions>
                                <v-row>
                                    <v-col class="text-right">
                                        <v-btn to="new-quote" color="primary" class="smc-text-button smc-medium-button" rounded>
                                            Request quotes
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import backgroundParallax from '@/components/widgets/background-parallax';
import boxViewSkeletonLoader from '@/components/widgets/box-view-skeleton-loader.vue';
import VueGauge from 'vue-gauge';
import { quoteRequestService } from '@/services';
import { quoteTypes } from '@/helpers';

export default {
    components: {
        backgroundParallax,
        boxViewSkeletonLoader,
        VueGauge,
    },
    data: () => ({
        loading: false,
        tooManyRequests: false,
        quoteDisclaimerShow: false,
        estimates: [],
    }),
    computed: {
        quoteRequest() {
            return this.$store.getters['quoteRequestCreate/getQuoteRequest'];
        },
        vehicle() {
            let vehicle = undefined;
            if (this.quoteRequest && this.quoteRequest.vehicle) {
                vehicle = this.quoteRequest.vehicle;
            }
            return vehicle;
        },
        quoteType() {
            return this.$store.getters['quoteRequestCreate/getQuoteType'];
        },
    },
    async created() {
        this.loading = true;
        let payload = _.cloneDeep(this.quoteRequest);

        payload.id = 0;
        payload.applicationUser = {
            id: 0,
        };
        payload.quoteRequestStatus = 0;
        payload.uniqueId = '00000000-0000-0000-0000-000000000000';

        if (_.some([quoteTypes.smcServiceLabel, quoteTypes.igsServiceLabel, quoteTypes.wbcServiceLabel, quoteTypes.igsPolicyLabel]), this.quoteType) {
            payload.items.unshift({ name: 'Maintenance service' });
        }

        let result = await quoteRequestService.getQuoteRequestEstimate(payload);

        if (result.status && result.status === 200) {
            this.estimates = result.estimates;
        }
        else if (!result.status || result.status === 429) {
            this.tooManyRequests = true;
        }
        this.loading = false;
    },
    methods: {
        itemFromPercent(item) {
            let fromPerc = (item.estimateFromPriceIncl * 100) / item.estimateToPriceIncl;
            if (fromPerc === 100) {
                fromPerc -= 0.01;
            }
            return fromPerc;
        },
        itemAveragePercent(item) {
            let avgPerc = (item.estimateAveragePriceIncl * 100) / item.estimateToPriceIncl;
            if (avgPerc === 100) {
                avgPerc -= 0.01;
            }
            return avgPerc;
        },
    },
};
</script>

<style lang="scss" scoped>
.smc-card-dialog.v-card > .v-card__title.smc-card-dialog-title-inset {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 300px;

  @media screen and (max-width: 600px) {
    width: 100%;
    border-bottom-right-radius: 0;
    border-top-right-radius: 30px;
  }
}

.smc-card-dialog.v-card>.v-card__subtitle.smc-card-dialog-subtitle-inset {
  @media screen and (max-width: 600px) {
    width: 100%;
    position: relative;
    border-bottom-right-radius: 32px;
  }
}

.smc-card-dialog .v-card__subtitle+.v-card__text {
  @media screen and (max-width: 600px) {
    padding-top: 32px;
  }
}
</style>

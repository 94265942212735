export default class QuoteAmendmentAccept {
    constructor(
        quoteId,
        approvedQuoteRequestItems,
        declinedQuoteRequestItems,
        quoteMessage,
        orderNumber,
    ) {
        this.quoteId = quoteId;
        this.approvedQuoteRequestItems = approvedQuoteRequestItems;
        this.declinedQuoteRequestItems = declinedQuoteRequestItems;
        this.quoteMessage = quoteMessage;
        this.orderNumber = orderNumber;
    }
};

export default class QuoteAccept {
    constructor(
        quoteId,
        applicationUser,
        dateModifiedUtc,
        quoteMessage,
        orderNumber,
    ) {
        this.quoteId = quoteId;
        this.applicationUser = applicationUser;
        this.dateModifiedUtc = dateModifiedUtc;
        this.quoteMessage = quoteMessage;
        this.orderNumber = orderNumber;
    }
};

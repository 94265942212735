<template>
    <ValidationObserver ref="validationObserver">
        <v-form v-model="valid" ref="form" lazy-validation @submit="submit($event)">
            <ValidationProvider
                v-slot="{ errors }"
                name="Email address"
                rules="required|email"
            >
                <v-text-field
                    id="login-email"
                    v-model="username"
                    :error-messages="errors"
                    prepend-inner-icon="mdi-account-circle"
                    label="Email Address"
                    outlined
                    rounded
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" name="Password" rules="required">
                <v-text-field
                    id="login-password"
                    v-model="password"
                    :error-messages="errors"
                    prepend-inner-icon="mdi-lock"
                    :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="passwordVisible ? 'text' : 'password'"
                    name="password"
                    label="Password"
                    outlined
                    rounded
                    @click:append="passwordVisible = !passwordVisible"
                />
            </ValidationProvider>
            <v-divider />
            <v-expand-transition>
                <v-row v-if="errorMessage" class="py-3">
                    <v-col>
                        <span class="error-label error--text">{{ errorMessage }}</span>
                    </v-col>
                </v-row>
            </v-expand-transition>
            <v-divider v-if="errorMessage" />
            <v-row class="pt-4">
                <v-col>
                    <v-btn
                        id="login-cancel"
                        color="darkGrey"
                        rounded
                        outlined
                        class="smc-medium-button"
                        @click="$emit('loginCancel')"
                    >
                        Cancel
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn
                        id="login-submit"
                        :disabled="!valid"
                        color="primary"
                        type="submit"
                        rounded
                        class="smc-medium-button"
                    >
                        Submit
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-btn
                        id="login-signinGoogle"
                        color="info"
                        @click="signinGoogle"
                        text
                        height="50"
                        class="smc-text-button pa-0"
                    >
                        <v-img
                            width="200"
                            src="@/assets/images/google_signin_buttons/web/2x/btn_google_signin_light_normal_web@2x.png"
                            lazy-src="@/assets/images/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png"
                        />
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p class="mx-auto mb-0">
                        Having trouble loggin in?
                        <v-btn
                            id="login-forgotPassword"
                            tile
                            text
                            @click="$emit('forgotPassword')"
                            class="primary--text text-none smc-text-button"
                        >
                            Forgot password
                        </v-btn>
                    </p>
                    <p class="mx-auto mb-0">
                        No account?
                        <v-btn
                            id="login-register"
                            tile
                            text
                            to="/account/signup"
                            target="_blank"
                            class="primary--text text-none smc-text-button"
                        >
                            Register
                        </v-btn>
                    </p>
                </v-col>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
import _ from 'lodash';
import { authService } from '@/services';
import { authStatuses } from '@/helpers';
import validate from '@/mixins/validator';

import AuthenticateLogin from '@/models/authenticate-login';

export default {
    name: 'account-login',
    mixins: [validate],
    data: () => {
        return {
            valid: false,
            loading: false,
            errorMessage: '',
            username: '',
            password: '',
            passwordVisible: false,
        };
    },
    props: {
        returnUrlProp: String,
    },
    watch: {
        username: function () {
            this.username = _.trim(this.username);
        },
    },
    methods: {
        async submit($event) {
            $event.preventDefault();
            this.errorMessage = '';
            if (await this.$refs.validationObserver.validate()) {
                this.loading = true;

                let result = await authService.authenticate(
                    new AuthenticateLogin(
                        _.trim(this.username),
                        _.trim(this.username),
                        this.password,
                    ),
                );

                if (result.status === 200 || result.status === 401) {
                    switch (result.data.status) {
                        case authStatuses.success:
                            this.$store.dispatch('user/setUser', {
                                username: this.username,
                                email: this.username,
                                isLoaded: false,
                            });

                            this.$store.dispatch('user/setToken', {
                                accessToken: {
                                    token: result.data.accessToken,
                                    tokenExpiresUtc: result.data.accessTokenExpiresUtc,
                                    tokenExpires: result.data.accessTokenExpires,
                                },
                                refreshToken: {
                                    token: result.data.refreshToken,
                                    tokenExpiresUtc: result.data.refreshTokenExpiresUtc,
                                    tokenExpires: result.data.refreshTokenExpires,
                                },
                            });

                            if (this.returnUrlProp) {
                                //this.$router.push(this.returnUrlProp);
                                window.location = this.returnUrlProp;
                            }
                            break;
                        case authStatuses.failed:
                            this.errorMessage = 'Login failed';
                            this.loading = false;
                            break;
                        case authStatuses.emailConfirmationRequired:
                            this.errorMessage = 'Unable to log in. Please check your email for more details.';
                            this.loading = false;
                            break;
                        case authStatuses.newPasswordRequired:
                            this.passwordRequired = true;
                            this.loading = false;
                            break;
                        case authStatuses.lockedOut:
                            this.errorMessage = 'Account locked. Please try again in a few minutes.';
                            this.loading = false;
                            break;
                        case authStatuses.notAllowed:
                            this.errorMessage = 'Login failed.';
                            this.loading = false;
                            break;
                        case authStatuses.requiresTwoFactor:
                    }
                }
                else {
                    this.errorMessage = 'Something went wrong.';
                }
            }
            this.loading = false;
        },
        async signinGoogle() {
            const googleUser = await this.$gAuth.signIn();
            if (!googleUser) {
                return null;
            }
            let authResponse = googleUser.getAuthResponse();
            let email = googleUser.getBasicProfile().getEmail();

            this.loading = true;
            let result = await authService.externalAuthenticate({
                idToken: authResponse.id_token,
                provider: 'google',
            });

            if (result.status === 200 && result.data.status === authStatuses.success) {
                this.$store.dispatch('user/setUser', {
                    username: email,
                    email: email,
                    isLoaded: false,
                });

                this.$store.dispatch('user/setToken', {
                    accessToken: {
                        token: result.data.accessToken,
                        tokenExpiresUtc: result.data.accessTokenExpiresUtc,
                        tokenExpires: result.data.accessTokenExpires,
                    },
                    refreshToken: {
                        token: result.data.refreshToken,
                        tokenExpiresUtc: result.data.refreshTokenExpiresUtc,
                        tokenExpires: result.data.refreshTokenExpires,
                    },
                });

                if (this.returnUrlProp) {
                    //this.$router.push(this.returnUrlProp);
                    window.location = this.returnUrlProp;
                }
            }
        },
    },
};
</script>

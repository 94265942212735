var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-2"},[(_vm.loading && !_vm.initialized)?_c('div',{staticClass:"mt-8"},_vm._l(([1, 2, 3]),function(i){return _c('box-view-skeleton-loader',{key:i,staticClass:"mb-6"})}),1):_c('div',{attrs:{"id":"requests-container"}},[_c('v-row',{staticClass:"mb-0"},[_c('v-col',[_c('v-text-field',{attrs:{"placeholder":"Search","prepend-inner-icon":"mdi-magnify","rounded":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),(_vm.stageProp != 1)?_c('v-col',[_c('v-select',{attrs:{"items":_vm.workshops,"return-object":"","item-text":"name","placeholder":"Workshops","prepend-inner-icon":"mdi-magnify","menu-props":{ offsetY: true },"rounded":"","hide-details":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',{attrs:{"size":"100","width":"100","tile":""}},[_c('img',{staticClass:"smc-search-image",attrs:{"src":data.item.logoUrl}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)]}}],null,false,814453750),model:{value:(_vm.selectedWorkshop),callback:function ($$v) {_vm.selectedWorkshop=$$v},expression:"selectedWorkshop"}})],1):_vm._e(),(_vm.stageProp == 5)?_c('v-col',[_c('v-menu',{ref:"monthFilterMenu",attrs:{"close-on-content-click":false,"return-value":_vm.monthFilterDate,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.monthFilterDate=$event},"update:return-value":function($event){_vm.monthFilterDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.monthFilterDate,"prepend-inner-icon":"mdi-calendar","hide-details":"","rounded":"","readonly":"","outlined":"","dense":""}},'v-text-field',attrs,false),on))]}}],null,false,3651142801),model:{value:(_vm.monthFilterMenu),callback:function ($$v) {_vm.monthFilterMenu=$$v},expression:"monthFilterMenu"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":""},on:{"input":function($event){_vm.$refs.monthFilterMenu.save(_vm.monthFilterDate);
                            _vm.monthFilterMenu = false;}},model:{value:(_vm.monthFilterDate),callback:function ($$v) {_vm.monthFilterDate=$$v},expression:"monthFilterDate"}})],1)],1):_vm._e(),_c('v-col',{staticClass:"shrink pl-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"rounded":"","color":"orange darken-2","small":"","fab":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.totalCount)+" ")])]}}])},[_c('span',[_vm._v(" Count of requests ")])])],1)],1),(!_vm.loading && _vm.initialized && _vm.requests.length === 0)?_c('div',[_c('p',{staticClass:"mt-8"},[_vm._v("No records")])]):_c('div',[_vm._l((_vm.requests),function(item){return _c('active-request-tab-item',{key:item.id,staticClass:"mb-6",attrs:{"enableCompleteButtonProp":_vm.stageProp == 4,"quoteRequestProp":item,"searchQueryProp":_vm.searchQueryList},on:{"resetRequests":_vm.resetRequests}})}),_c('scroll-pagination',{ref:"scrollPagination",attrs:{"loader-method-prop":_vm.searchRequests,"loader-disable-prop":_vm.requests.length >= _vm.totalCount}})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="home container" :class="$vuetify.breakpoint.xsOnly ? 'px-0' : ''">
        <background-parallax :backgroundOpacityProp="'0.6'" />
        <div class="mt-12 pt-12" style="position: relative"></div>
        <v-sheet class="mb-12 box box-rounded" elevation="6">
            <v-row v-if="!vehicleMapping">
                <v-col>
                    <v-skeleton-loader class="mr-5 inline-element" type="image" style="height: 125px; min-width: 125px; width: 250px;" />
                </v-col>
                <v-col>
                    <v-skeleton-loader class="pb-3" type="heading" />
                    <v-skeleton-loader type="paragraph" />
                    <v-skeleton-loader type="paragraph" />
                </v-col>
                <v-col class="text-right">
                    <v-skeleton-loader class="mt-5 ml-5" type="heading" />
                </v-col>
            </v-row>
            <v-card v-else color="white" class="smc-card-dialog">
                <v-card-title class="smc-card-dialog-title-inset primary white--text">
                    <span class="mx-auto">{{ vehicleMapping.name }}</span>
                </v-card-title>
                <v-card-subtitle class="smc-card-dialog-subtitle-inset">
                    <span class="font-weight-medium">
                        {{ dateHelper.formatDateLong(vehicleMapping.dateCreatedUtc) }}
                    </span>
                </v-card-subtitle>
                <v-card-text>
                    <v-row class="mt-4">
                        <v-col sm="6" md="4">
                            <div class="smc-card-avatar">
                                <img
                                    v-if="vehicleMapping && vehicleMapping.vehicle && vehicleMapping.vehicle.imageUrl"
                                    :src="vehicleMapping.vehicle.imageUrl"
                                    class="smc-search-result-image"
                                />
                            </div>
                            <v-dialog v-model="vehicleDetailsShow" width="800">
                                <v-toolbar color="primary" dark height="48">
                                    Vehicle Details
                                </v-toolbar>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        secondary
                                        rounded
                                        outlined
                                        color="info"
                                        class="px-2"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        Vehicle Details
                                    </v-btn>
                                </template>
                                <v-card>
                                    <vehicle-details
                                        :vehicleMappingProp="{ vehicle: vehicleMapping.vehicle }"
                                        :hideImageProp="true"
                                    />
                                    <v-divider />
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-btn
                                            color="primary"
                                            rounded
                                            class="px-4"
                                            @click="vehicleDetailsShow = false"
                                        >
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" style="text-align: left">
                            <v-label>{{ vehicleMapping.vehicle.year }}</v-label>
                            <br />
                            <v-label>{{ vehicleMapping.vehicle.brand }}</v-label>
                            <br />
                            <v-label>{{ vehicleMapping.vehicle.range }}</v-label>
                            <br />
                            <v-label>{{ vehicleMapping.vehicle.model }}</v-label>
                            <br />
                            <v-label>
                                <strong>Mileage:</strong>
                                {{ vehicleMapping.vehicleMileage }} km
                            </v-label>
                        </v-col>
                        <v-divider
                            v-if="$vuetify.breakpoint.smAndUp"
                            vertical
                            role="presentation"
                        />
                        <v-col style="text-align: left">
                            <v-label>{{ vehicleMapping.vehicleColour }}</v-label>
                            <br />
                            <v-label>{{ vehicleMapping.vehicleVinNumber }}</v-label>
                            <br />
                            <v-label>{{ vehicleMapping.vehicleEngineNumber }}</v-label>
                            <br />
                            <v-label>{{ vehicleMapping.vehicleLicensePlate }}</v-label>
                            <br />
                        </v-col>
                        <v-divider
                            v-if="$vuetify.breakpoint.smAndUp"
                            vertical
                            role="presentation"
                        />
                        <v-col>
                            <v-btn @click="newQuote" color="primary" rounded block>
                                <span>New Request</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-sheet>

        <v-card
            class="box box-rounded box-transparent box-transparent-light"
            elevation="12"
        >
            <v-card-subtitle>
                <v-breadcrumbs class="smc-breadcrumbs">
                    <v-breadcrumbs-item class="smc-breadcrumbs-item" to="/">
                        <v-icon>mdi-home</v-icon>
                    </v-breadcrumbs-item>
                    <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
                    <v-breadcrumbs-item
                        v-if="isOrganization"
                        class="smc-breadcrumbs-item"
                        to="/active-requests"
                    >
                        Active Requests
                    </v-breadcrumbs-item>
                    <v-breadcrumbs-item
                        v-else
                        class="smc-breadcrumbs-item"
                        to="/my-garage"
                    >
                        My Garage
                    </v-breadcrumbs-item>
                    <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
                    <v-breadcrumbs-item class="smc-breadcrumbs-item">
                        Vehicle History
                    </v-breadcrumbs-item>
                </v-breadcrumbs>
                <div class="sort-container">
                    Sort by:
                    <v-chip>{{ sortModel.selectedSort.text }}</v-chip>
                    <v-hover v-slot="{ hover }">
                        <v-btn icon @click="invertSort">
                            <v-icon :color="hover ? 'primary' : 'default'">
                                {{
                                    sortModel.dir === "desc"
                                        ? "mdi-arrow-down-thick"
                                        : "mdi-arrow-up-thick"
                                }}
                            </v-icon>
                        </v-btn>
                    </v-hover>
                </div>
            </v-card-subtitle>

            <v-card-text
                v-if="!loading && vehicleId && !allVehicles"
                class="py-0 my-n3 text-right"
            >
                <v-btn rounded right color="primary" @click="loadQuoteRequests(true)">
                    All history
                </v-btn>
            </v-card-text>

            <v-card-text class="mt-4">
                <div v-if="loading">
                    <box-view-skeleton-loader
                        v-for="i in [1, 2, 3]"
                        :key="i"
                        class="mb-6"
                    />
                </div>
                <quote-request-view
                    v-for="(item, i) in sortedQuoteRequests"
                    :key="i"
                    :quoteRequestProp="item"
                    class="mb-6"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import _ from 'lodash';
import backgroundParallax from '@/components/widgets/background-parallax';
import quoteRequestView from '@/components/quote-request-view';
import boxViewSkeletonLoader from '@/components/widgets/box-view-skeleton-loader';
import vehicleDetails from '@/components/vehicle-details';
import { quoteRequestService } from '@/services';
import { dateHelper } from '@/helpers';

export default {
    name: 'quote-requests',
    components: {
        backgroundParallax,
        quoteRequestView,
        boxViewSkeletonLoader,
        vehicleDetails,
    },
    props: {
        vehicleId: String,
    },
    data: () => {
        return {
            dateHelper,
            loading: true,

            allVehicles: false,
            sortModel: {
                dir: 'desc',
                selectedSort: { text: 'Date submitted', value: 'dateCreatedUtc' },
            },
            sortOptions: {
                dateSubmitted: { text: 'Date submitted', value: 'dateCreatedUtc' },
            },

            vehicleDetailsShow: false,
            vehicleMapping: undefined,
            quoteRequests: [],
        };
    },
    computed: {
        isOrganization() {
            return this.$store.getters['user/isOrganization'];
        },
        sortedQuoteRequests() {
            let sortedStuff = _.orderBy(
                this.quoteRequests,
                [this.sortModel.selectedSort.value],
                [this.sortModel.dir],
            );
            return sortedStuff;
        },
    },
    async mounted() {
        await this.loadQuoteRequests(false);
    },
    methods: {
        invertSort() {
            if (this.sortModel.dir === 'asc') {
                this.sortModel.dir = 'desc';
            }
            else {
                this.sortModel.dir = 'asc';
            }
        },
        async loadQuoteRequests(allVehicles) {
            this.loading = true;
            let vehicleId = this.vehicleId ? (allVehicles ? 0 : this.vehicleId) : 0;
            this.allVehicles = allVehicles;
            let vehicleHistory = await quoteRequestService.getQuoteRequests(
                vehicleId,
            );
            this.vehicleMapping = vehicleHistory.vehicleMapping;
            this.quoteRequests = vehicleHistory.quoteRequests;
            this.loading = false;
        },
        newQuote() {
            this.$router.push({
                name: 'new-quote',
                params: { vehicleMappingProp: this.vehicleMapping },
            });
        },
    },
};
</script>

<style scoped>
@import "../assets/scss/vuetify-elements/card.scss";
@import "../assets/scss/vuetify-elements/sheet.scss";

.page-header {
  position: relative;
}

.smc-breadcrumbs {
  padding-left: 12px;
}

.smc-breadcrumbs .smc-breadcrumbs-item {
  font-weight: 600;
}
</style>

<style lang="scss" scoped>
.sort-container {
  text-align: right;

  @media screen and (min-width: 601px) {
    position: absolute;
    right: 16px;
    top: 26px;
  }
}
</style>

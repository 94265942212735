import { render, staticRenderFns } from "./quote-details-view.vue?vue&type=template&id=7424e29e&scoped=true&"
import script from "./quote-details-view.vue?vue&type=script&lang=js&"
export * from "./quote-details-view.vue?vue&type=script&lang=js&"
import style0 from "./quote-details-view.vue?vue&type=style&index=0&id=7424e29e&scoped=true&lang=css&"
import style1 from "./quote-details-view.vue?vue&type=style&index=1&id=7424e29e&lang=scss&scoped=true&"
import style2 from "./quote-details-view.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7424e29e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCol,VDialog,VDivider,VIcon,VRow,VSimpleTable,VSlideXTransition})

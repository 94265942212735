<template>
    <v-sheet class="pa-2">
        <div class="mt-8" v-if="loading && !initialized">
            <box-view-skeleton-loader
                v-for="i in [1, 2, 3]"
                :key="i"
                class="mb-6"
            />
        </div>
        <div id="requests-container" v-else>
            <v-row class="mb-0">
                <v-col>
                    <v-text-field
                        v-model="searchQuery"
                        placeholder="Search"
                        prepend-inner-icon="mdi-magnify"
                        rounded
                        hide-details
                        outlined
                        dense
                    />
                </v-col>
                <v-col v-if="stageProp != 1">
                    <v-select
                        v-model="selectedWorkshop"
                        :items="workshops"
                        return-object
                        item-text="name"
                        placeholder="Workshops"
                        prepend-inner-icon="mdi-magnify"
                        :menu-props="{ offsetY: true }"
                        rounded
                        hide-details
                        outlined
                        dense
                    >
                        <template #item="data">
                            <v-list-item-avatar size="100" width="100" tile>
                                <img class="smc-search-image" :src="data.item.logoUrl" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title style="text-align: left">
                                    {{ data.item.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-select>
                </v-col>
                <v-col v-if="stageProp == 5">
                    <v-menu
                        ref="monthFilterMenu"
                        v-model="monthFilterMenu"
                        :close-on-content-click="false"
                        :return-value.sync="monthFilterDate"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template #activator="{ on, attrs }">
                            <v-text-field
                                :value="monthFilterDate"
                                prepend-inner-icon="mdi-calendar"
                                hide-details
                                rounded
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker
                            v-model="monthFilterDate"
                            type="month"
                            no-title
                            scrollable
                            @input="
                                $refs.monthFilterMenu.save(monthFilterDate);
                                monthFilterMenu = false;
                            "
                        />
                    </v-menu>
                </v-col>
                <v-col class="shrink pl-0">
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn
                                rounded
                                color="orange darken-2"
                                small
                                fab
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ totalCount }}
                            </v-btn>
                        </template>
                        <span> Count of requests </span>
                    </v-tooltip>
                </v-col>
            </v-row>

            <div v-if="!loading && initialized && requests.length === 0">
                <p class="mt-8">No records</p>
            </div>
            <div v-else>
                <active-request-tab-item
                    class="mb-6"
                    v-for="item in requests"
                    :key="item.id"
                    :enableCompleteButtonProp="stageProp == 4"
                    :quoteRequestProp="item"
                    :searchQueryProp="searchQueryList"
                    @resetRequests="resetRequests"
                />

                <scroll-pagination
                    ref="scrollPagination"
                    :loader-method-prop="searchRequests"
                    :loader-disable-prop="requests.length >= totalCount"
                />
            </div>
        </div>
    </v-sheet>
</template>

<script>
import _ from 'lodash';
import debounce from 'debounce';

import boxViewSkeletonLoader from '@/components/widgets/box-view-skeleton-loader';
import activeRequestTabItem from '@/components/active-request-tab-item';
import scrollPagination from '@/components/scroll-pagination';
import { quoteRequestService } from '@/services';

export default {
    name: 'active-request-tab',
    components: {
        boxViewSkeletonLoader,
        activeRequestTabItem,
        scrollPagination,
    },
    props: {
        stageProp: String,
    },
    data: () => ({
        searchQuery: '',
        loading: true,
        initialized: false,

        tottalDisplayItems: 0,
        currentPage: 1,
        totalCount: 0,
        itemsPerPage: 20,

        selectedWorkshop: {},
        workshops: [
            {
                name: 'All',
                id: 0,
                logoUrl: require('@/assets/images/smc-logo.png'),
            },
        ],

        monthFilterMenu: false,
        monthFilterDate: new Date().toISOString().substr(0, 7),

        requests: [],
        sortModel: {
            dir: 'desc',
            selectedSort: { text: 'Date added', value: 'dateCreatedUtc' },
        },
    }),
    computed: {
        searchQueryList() {
            let queryList = [];

            if (this.searchQuery) {
                queryList.push(this.searchQuery);
            }
            if (
                this.selectedWorkshop
                && this.selectedWorkshop.id
                && this.selectedWorkshop.id !== 0
            ) {
                queryList.push(this.selectedWorkshop.name);
            }

            return queryList;
        },
    },
    async created() {
        this.searchRequests = debounce(this.searchRequests, 500);

        let result = await quoteRequestService.queryActiveRequestsWorkshops(
            this.stageProp,
        );
        if (result.status === 200) {
            this.workshops.push(...result.data);
        }

        await this.searchRequests();
    },
    watch: {
        searchQuery: {
            handler: async function () {
                this.currentPage = 1;
                this.loading = true;
                this.requests = [];
                await this.searchRequests();
            },
        },
        selectedWorkshop: {
            handler: async function () {
                this.currentPage = 1;
                this.loading = true;
                this.requests = [];
                await this.searchRequests();
            },
        },
        monthFilterDate: {
            handler: async function () {
                this.currentPage = 1;
                this.loading = true;
                this.requests = [];
                await this.searchRequests();
            },
        },
    },
    methods: {
        async resetRequests() {
            this.currentPage = 1;
            this.requests = [];
            await this.searchRequests();
        },
        async searchRequests() {
            this.loading = true;

            let headers = [
                {
                    value: 'workshopId',
                    filter: this.selectedWorkshop.id,
                },
            ];

            if (this.stageProp == 5) {
                headers.push({
                    value: 'invoiceDateMonth',
                    filter: this.monthFilterDate,
                });
            }

            let result = await quoteRequestService.queryActiveRequests(
                this.stageProp,
                {
                    filter: _.trim(this.searchQuery),
                    page: this.currentPage,
                    itemsPerPage: this.itemsPerPage,
                    headers: headers,
                },
            );
            this.requests.push(...result.items);
            this.totalCount = result.totalDisplayItems;
            this.currentPage++;
            this.loading = false;
            this.initialized = true;
        },
    },
};
</script>

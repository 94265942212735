<template>
    <v-card
        color="white"
        class="smc-card-dialog"
        elevation="6"
        :class="
            organizationSubmittedForApproval === true ? 'organization-approval-card-success' : organizationSubmittedForApproval === false ? 'organization-approval-card-error' : ''
        "
    >
        <v-card-title
            :id="`quote-heading-${quoteModel.id}`"
            class="smc-card-dialog-title-inset white--text"
            :class="organizationSubmittedForApproval === true ? 'green' : 'primary'"
        >
            <span v-if="isInvoice || isPaidInvoice" class="mx-auto">Invoice</span>
            <span v-else class="mx-auto">Response #{{ quoteCountProp }}</span>
        </v-card-title>
        <v-card-subtitle class="smc-card-dialog-subtitle-inset">
            <span class="font-weight-medium my-auto">Workshop's certifications</span>
            <v-img
                src="@/assets/images/qualifications/certified.svg"
                lazy-src="@/assets/images/qualifications/certified.svg"
                class="mx-2 certify-badge"
                width="62"
                height="50"
            />
            <v-img
                v-if="quoteModel.workshop.isPosh"
                src="@/assets/images/qualifications/posh.svg"
                lazy-src="@/assets/images/qualifications/posh.svg"
                class="mx-2 posh-badge"
                width="42"
                height="50"
            />
        </v-card-subtitle>
        <v-card-text>
            <h3 class="smc-card-dialog-mid-heading">
                {{ quoteModel.workshop.name }}
            </h3>
            <v-row class="mt-4">
                <v-col cols="12" sm="12" md="4">
                    <div class="smc-card-avatar mx-auto">
                        <a @click="toggleQuotePanel">
                            <img
                                :src="quoteModel.workshop.logoUrl"
                                class="smc-search-result-image"
                            />
                        </a>
                    </div>
                    <span><v-chip small>Quote Time:</v-chip>
                        {{ dateHelper.formatTimeSpan(quoteTime) }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="5" class="text-left px-8">
                    <v-row>
                        <v-col>
                            <v-label>Workshop's location</v-label>
                        </v-col>
                        <v-col class="text-right">
                            <v-dialog
                                v-model="addressDialog"
                                width="900px"
                                :fullscreen="this.$vuetify.breakpoint.smAndDown"
                            >
                                <template #activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        outlined
                                        rounded
                                        color="primary"
                                        small
                                        class="text-none"
                                    >
                                        <v-icon left>mdi-google-maps</v-icon>Address
                                    </v-btn>
                                </template>
                                <v-card
                                    class="box"
                                    :class="
                                        this.$vuetify.breakpoint.smAndDown
                                            ? 'box-squared'
                                            : 'box-rounded'
                                    "
                                >
                                    <v-card-title class="primary white--text mb-4">
                                        {{ quoteModel.workshop.name }}
                                    </v-card-title>
                                    <v-card-text>
                                        <address-view
                                            :addressProp="quoteModel.workshop.address"
                                            :quoteIdProp="quoteModel.id"
                                        />
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-actions>
                                        <v-btn
                                            color="darkGrey"
                                            class="ml-4"
                                            rounded
                                            outlined
                                            @click="
                                                routeDialog = true;
                                                addressDialog = false;
                                            "
                                        >
                                            <v-icon left>mdi-map-marker-distance</v-icon>Directions
                                        </v-btn>
                                        <v-spacer />
                                        <v-btn
                                            color="primary"
                                            class="mr-4"
                                            rounded
                                            @click="addressDialog = false"
                                        >
                                            <v-icon>mdi-close-thick</v-icon>Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-label>Workshop directions</v-label>
                        </v-col>
                        <v-col class="text-right">
                            <v-dialog
                                v-model="routeDialog"
                                width="900px"
                                :fullscreen="this.$vuetify.breakpoint.smAndDown"
                            >
                                <template #activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        outlined
                                        rounded
                                        color="primary"
                                        small
                                        class="text-none"
                                    >
                                        <v-icon left>mdi-map-marker-distance</v-icon>
                                        Directions
                                    </v-btn>
                                </template>
                                <v-card
                                    class="box"
                                    :class="
                                        this.$vuetify.breakpoint.smAndDown
                                            ? 'box-squared'
                                            : 'box-rounded'
                                    "
                                >
                                    <v-card-title class="primary white--text mb-4">
                                        Directions
                                    </v-card-title>
                                    <v-card-text>
                                        <address-route-view
                                            :fromAddressProp="addressProp"
                                            :toAddressProp="quoteModel.workshop.address"
                                            :quoteIdProp="quoteModel.id"
                                        />
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-actions>
                                        <v-btn
                                            v-if="$vuetify.breakpoint.mdAndUp"
                                            color="darkGrey"
                                            class="ml-4"
                                            rounded
                                            outlined
                                            @click="
                                                printInstructions(`directions-content-${quoteModel.id}`)
                                            "
                                        >
                                            <v-icon class="mr-2">mdi-printer</v-icon>
                                            Directions and map
                                        </v-btn>
                                        <v-btn
                                            v-if="$vuetify.breakpoint.mdAndUp"
                                            color="darkGrey"
                                            rounded
                                            outlined
                                            @click="
                                                printInstructions(`directions-panel-${quoteModel.id}`)
                                            "
                                        >
                                            <v-icon class="mr-2">mdi-printer</v-icon>directions only
                                        </v-btn>
                                        <v-spacer />
                                        <v-btn
                                            color="primary"
                                            class="mr-4"
                                            rounded
                                            @click="routeDialog = false"
                                        >
                                            <v-icon>mdi-close-thick</v-icon>Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-label>Selected service date</v-label>
                        </v-col>
                        <v-col class="text-right">
                            <v-chip small outlined color="secondary">
                                {{ dateHelper.formatDateLong(quoteModel.serviceDate) }}
                            </v-chip>
                        </v-col>
                    </v-row>
                    <v-row v-if="showRatings === 'true'">
                        <v-col>
                            <v-label>Workshop's rating</v-label>
                        </v-col>
                        <v-col class="text-right">
                            <v-rating
                                v-model="quoteModel.workshop.rating"
                                background-color="midGrey"
                                color="primary"
                                style="margin-top: -5px"
                                size="16"
                                half-increments
                                readonly
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="quoteModel.workshop.workshopReviews.length > 0">
                        <v-col>
                            <v-label>Workshop's reviews</v-label>
                        </v-col>
                        <v-col class="text-right">
                            <v-dialog
                                v-model="reviewDialog"
                                width="900px"
                                :fullscreen="this.$vuetify.breakpoint.smAndDown"
                            >
                                <template #activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        outlined
                                        rounded
                                        color="primary"
                                        small
                                        class="text-none"
                                    >
                                        View
                                        {{
                                            quoteModel.workshop.workshopReviews.length === 1
                                                ? "1 review"
                                                : `${quoteModel.workshop.workshopReviews.length} reviews`
                                        }}
                                    </v-btn>
                                </template>
                                <v-card
                                    class="box"
                                    :class="
                                        this.$vuetify.breakpoint.smAndDown
                                            ? 'box-squared'
                                            : 'box-rounded'
                                    "
                                >
                                    <v-card-title class="primary white--text mb-4">
                                        Workshop reviews
                                    </v-card-title>
                                    <v-card-text>
                                        <v-carousel
                                            cycle
                                            interval="10000"
                                            height="600"
                                            show-arrows-on-hover
                                            hide-delimiter-background
                                        >
                                            <v-carousel-item
                                                v-for="item in quoteModel.workshop.workshopReviews"
                                                :key="item.id"
                                                ripple
                                            >
                                                <v-sheet height="100%" color="secondary">
                                                    <v-row
                                                        class="fill-height"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-col class="pa-12 mx-4">
                                                            <v-row>
                                                                <v-col cols="12">
                                                                    <div
                                                                        class="body-1"
                                                                        style="white-space: pre-line"
                                                                    >
                                                                        {{ item.review }}
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="12">
                                                                    <v-rating
                                                                        v-if="item.rating"
                                                                        v-model="item.rating"
                                                                        readonly
                                                                        color="amber"
                                                                        background-color="#amber"
                                                                        :length="item.rating"
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-sheet>
                                            </v-carousel-item>
                                        </v-carousel>
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-btn
                                            color="primary"
                                            class="mr-4"
                                            rounded
                                            @click="reviewDialog = false"
                                        >
                                            <v-icon>mdi-close-thick</v-icon>Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>
                </v-col>
                <v-divider vertical role="presentation" />
                <v-col>
                    <p v-if="hidePriceProp">&nbsp;</p>
                    <p class="title font-weight-bold" v-else>
                        Price:
                        <span style="white-space: nowrap" class="primary--text">
                            {{ getQuoteTotal() | currency }}
                        </span>
                    </p>
                    <div
                        v-if="
                            quoteModel.quoteStatus === quoteStatuses.pendingApproval.id
                                || quoteModel.quoteStatus === quoteStatuses.booked.id
                        "
                    >
                        <v-btn
                            v-if="quotePanel !== 0"
                            color="primary"
                            rounded
                            outlined
                            @click="showPanel"
                        >
                            View
                        </v-btn>
                        <v-btn
                            v-if="quotePanel === 0"
                            color="primary"
                            rounded
                            outlined
                            @click="hidePanel()"
                        >
                            Hide
                        </v-btn>
                        <br />
                        <v-chip color="primary" class="mt-6" v-if="quoteModel.isExpired">
                            Response expired
                            <v-icon class="ml-2">mdi-alert</v-icon>
                        </v-chip>
                        <p class="mt-4">Click here to view response.</p>
                        <v-chip
                            v-if="organizationSubmittedForApproval !== null"
                            :color="organizationSubmittedForApproval === true ? 'green' : 'orange'"
                            class="white--text"
                        >
                            {{ organizationSubmittedForApproval === true ? 'Submitted for Approval' : 'Error sending to Nexus' }}
                        </v-chip>
                    </div>
                    <div v-else-if="quoteHasPendingAmendment()">
                        <v-btn
                            v-if="quotePanel !== 0"
                            color="info"
                            rounded
                            outlined
                            @click="showPanel"
                        >
                            View Change
                        </v-btn>
                        <v-btn
                            v-if="quotePanel === 0"
                            color="info"
                            rounded
                            outlined
                            @click="hidePanel()"
                        >
                            Hide
                        </v-btn>
                        <p class="mt-4">Click here to view change.</p>
                    </div>
                    <div v-else-if="isPaidInvoice">
                        <v-icon color="green" large>mdi-check-circle</v-icon>
                        <p class="mt-2">Invoice paid</p>
                    </div>
                    <div v-else>
                        <v-btn
                            v-if="quotePanel !== 0"
                            color="primary"
                            rounded
                            outlined
                            @click="showPanel"
                        >
                            View
                        </v-btn>
                        <v-btn
                            v-if="quotePanel === 0"
                            color="primary"
                            rounded
                            outlined
                            @click="hidePanel()"
                        >
                            Hide
                        </v-btn>
                        <p class="mt-4">Click here to view.</p>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider />
        <v-expansion-panels v-model="quotePanel" hover focusable>
            <v-expansion-panel class="rounded-bottom">
                <v-expansion-panel-header class="rounded-bottom">
                    <v-label v-if="isInvoice || isPaidInvoice">
                        Show Invoice Details
                    </v-label>
                    <v-label v-else>Show Response Details</v-label>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="pt-4">
                        <v-col>
                            <quote-details-view
                                :quoteProp="quoteModel"
                                :referenceProp="smcReferenceProp"
                                :userProp="userProp"
                                :hidePriceProp="hidePriceProp"
                                @quoteUpdate="updateQuote($event)"
                            />
                        </v-col>
                    </v-row>

                    <v-slide-x-transition>
                        <v-simple-table v-if="amendmentRequiredErrors.length > 0">
                            <tbody>
                                <tr
                                    v-for="(error, index) in amendmentRequiredErrors"
                                    :key="index"
                                >
                                    <td class="text-left error--text">
                                        Please either accept or decline the amendment for
                                        <strong>{{ error }}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-slide-x-transition>

                    <v-row v-if="quoteModel">
                        <v-col class="text-center">
                            <v-btn
                                color="success"
                                class="mx-2 mt-4"
                                rounded
                                outlined
                                v-if="
                                    quoteModel.quoteStatus === quoteStatuses.pendingApproval.id
                                        && quoteModel.isExpired !== true
                                        && isOrganization
                                        && !quoteRequestOrganizationShareProp
                                "
                                @click="openNexusDialog"
                            >
                                Submit to Nexus
                            </v-btn>
                            <v-btn
                                color="primary"
                                class="mx-2 mt-4"
                                rounded
                                outlined
                                @click="acceptQuote"
                                v-else-if="
                                    quoteModel.quoteStatus === quoteStatuses.pendingApproval.id
                                        && quoteModel.isExpired !== true
                                "
                            >
                                Accept
                            </v-btn>
                            <v-chip
                                v-if="quoteModel.isExpired"
                                color="primary"
                                class="mx-2 mt-4"
                            >
                                Response expired
                                <v-icon class="ml-2">mdi-alert</v-icon>
                            </v-chip>
                            <v-btn
                                color="primary"
                                class="mx-2 mt-4"
                                rounded
                                outlined
                                @click="confirmAmendment"
                                v-if="quoteHasPendingAmendment()"
                            >
                                Confirm
                            </v-btn>

                            <br />

                            <v-btn
                                v-if="isOrganization"
                                class="mx-2 mt-4"
                                rounded
                                color="primary"
                                :loading="shareLoading"
                                @click="copyQuoteViewer(false)"
                            >
                                Copy Link
                            </v-btn>
                            <v-btn
                                v-if="isOrganization"
                                class="mx-2 mt-4"
                                rounded
                                color="primary"
                                :href="shareLink"
                                target="_blank"
                            >
                                Open/Share Quote
                            </v-btn>
                            <v-snackbar
                                app
                                color="primary"
                                v-model="copySnackbar"
                                :timeout="3000"
                                centered
                            >
                                Shareable quote link copied to clipboard
                            </v-snackbar>
                            <v-snackbar
                                app
                                color="primary"
                                v-model="sharedSnackbar"
                                :timeout="3000"
                                centered
                            >
                                Sent to Nexus
                            </v-snackbar>
                            <!--
              <v-btn
                v-if="isOrganization"
                color="primary"
                class="mx-2 mt-4"
                rounded
                :loading="shareLoading"
                @click="openQuoteViewer(false)"
              >
                Open/Print Quote
              </v-btn>
              -->
                            <v-dropdown
                                v-if="
                                    quoteModel.quoteStatus === quoteStatuses.pendingApproval.id
                                        || quoteModel.quoteStatus === quoteStatuses.booked.id
                                        || quoteModel.quoteStatus === quoteStatuses.inProgress.id
                                        || quoteModel.quoteStatus === quoteStatuses.amendmentPending.id
                                        || quoteModel.quoteStatus === quoteStatuses.proForma.id
                                "
                                class="d-inline-block"
                                container="#smc-app"
                                :triggers="['hover']"
                                :disabled="
                                    quoteModel.quoteMessages === undefined
                                    || quoteModel.quoteMessages === null
                                        || quoteModel.quoteMessages.length < 10
                                "
                            >
                                <v-btn
                                    color="primary"
                                    class="mx-2 mt-4"
                                    rounded
                                    @click="openWorkshopMessageDialog"
                                >
                                    Message Workshop
                                </v-btn>
                                <!--
                <v-btn
                  v-if="isOrganization"
                  color="primary"
                  class="mx-2 mt-4"
                  rounded
                  @click="openOrgOrderDialog"
                >
                  Attach Order
                </v-btn>
                -->
                                <template #popper>
                                    <v-card flat>
                                        <v-card-text class="pa-4">
                                            Maximum allowed messages reached
                                        </v-card-text>
                                    </v-card>
                                </template>
                            </v-dropdown>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <!-- dialogs -->
        <v-dialog v-model="loginDialog" persistent content-class="smc-dialog">
            <v-card class="smc-card-dialog" color="white">
                <v-card-title
                    class="primary white--text text-center smc-card-dialog-title smc-card-dialog-title-narrow"
                >
                    <span class="mx-auto">LOGIN</span>
                </v-card-title>
                <div class="smc-card-top-bar">
                    <v-btn
                        icon
                        color="midGrey"
                        class="float-right"
                        height="32"
                        width="32"
                        @click="loginDialog = false"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <p>
                        You need to have an account in order to select a response. Please
                        either login, or register.
                    </p>
                    <account-login
                        :returnUrlProp="this.$route.fullPath"
                        @loginCancel="loginDialog = false"
                        @forgotPassword="
                            loginDialog = false;
                            forgotPasswordDialog = true;
                        "
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="forgotPasswordDialog"
            persistent
            content-class="smc-dialog"
            :width="$vuetify.breakpoint.smAndUp ? '650' : '100vw'"
        >
            <v-card class="smc-card-dialog" color="white">
                <v-card-title
                    class="primary white--text text-center smc-card-dialog-title smc-card-dialog-title-narrow"
                >
                    <span class="mx-auto">RESET</span>
                </v-card-title>
                <div class="smc-card-top-bar">
                    <v-btn
                        icon
                        color="midGrey"
                        class="float-right"
                        height="32"
                        width="32"
                        @click="forgotPasswordDialog = false"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <account-forgot-password
                        :returnUrlProp="this.$route.fullPath"
                        @resetForgotPassword="
                            forgotPasswordDialog = false;
                            loginDialog = true;
                        "
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="confirmDialog"
            persistent
            scrollable
            content-class="smc-dialog"
        >
            <v-card class="smc-card-dialog" color="white">
                <v-card-title
                    class="primary white--text text-center smc-card-dialog-title smc-card-dialog-title-narrow"
                >
                    <span class="mx-auto">CONFIRM</span>
                </v-card-title>
                <div class="smc-card-top-bar">
                    <v-btn
                        icon
                        color="midGrey"
                        class="float-right"
                        height="32"
                        width="32"
                        @click="confirmDialog = false"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </div>
                <v-sheet class="smc-card-dialog-content-sheet">
                    <v-card-text>
                        <p>
                            Are you sure you want to accept this response from
                            <br />
                            <strong>{{ quoteModel.workshop.name }}</strong>
                            ?
                        </p>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                offset="0"
                                :offset-sm="hidePriceProp ? 3 : 0"
                            >
                                <v-img
                                    :src="quoteModel.workshop.logoUrl"
                                    :lazy-src="quoteModel.workshop.logoUrl"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                class="position-relative"
                                v-if="!hidePriceProp"
                            >
                                <span
                                    class="primary--text title font-weight-bold position-absolute horizontal-vertical-center quote-total"
                                >
                                    {{ getQuoteTotal() | currency }}
                                </span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <div v-if="userEmailProp !== currentUserEmail">
                        <v-divider />
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <p class="mb-0">
                                        This request was submitted by email address
                                        <span class="primary--text font-weight-bold">
                                            {{ userEmailProp }}
                                        </span>
                                        , but you are logged in with email address
                                        <span class="primary--text font-weight-bold">
                                            {{ currentUserEmail }}
                                        </span>
                                        .
                                        <br />
                                        <br />
                                        If you accept the response while logged in with this email
                                        address, the entire request will be re-assigned to the
                                        logged in user.
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </div>
                    <v-divider />
                    <div v-if="isOrganization">
                        <v-row class="mt-4">
                            <v-col class="mx-4">
                                <v-card>
                                    <v-sheet class="order-tab-container">
                                        <v-tabs
                                            v-model="orderCaptureTab"
                                            fixed-tabs
                                            center-active
                                            centered
                                            background-color="primary"
                                            active-class="white red--text border pa-2"
                                            slider-color="blue"
                                        >
                                            <v-tabs-slider />

                                            <v-tab href="#tab-1">
                                                Attach File
                                                <v-spacer />
                                                <v-icon>mdi-file-outline</v-icon>
                                            </v-tab>

                                            <v-tab href="#tab-2">
                                                Manual Order No
                                                <v-spacer />
                                                <v-icon>mdi-numeric-9-box-multiple-outline</v-icon>
                                            </v-tab>
                                        </v-tabs>

                                        <v-tabs-items v-model="orderCaptureTab">
                                            <v-tab-item value="tab-1">
                                                <v-row class="mt-4">
                                                    <v-col class="mx-4">
                                                        <validation-provider
                                                            v-slot="{ errors }"
                                                            name="Attach Order"
                                                            id="noteAttachment"
                                                            :rules="`ext:pdf|size:5000`"
                                                        >
                                                            <v-file-input
                                                                v-model="attachment"
                                                                :error-messages="errors"
                                                                label="Attach Order"
                                                                prepend-inner-icon="mdi-paperclip"
                                                                prepend-icon
                                                                outlined
                                                                rounded
                                                                counter
                                                                show-size
                                                                accept="application/pdf"
                                                            >
                                                                <template #selection="{ index, text }">
                                                                    <v-chip
                                                                        v-if="index < 2"
                                                                        color="primary accent-4"
                                                                        dark
                                                                        label
                                                                        small
                                                                    >
                                                                        {{ text }}
                                                                    </v-chip>
                                                                    <span
                                                                        v-else-if="index === 2"
                                                                        class="overline grey--text text--darken-3 mx-2"
                                                                    >
                                                                        +{{ files.length - 2 }} File(s)
                                                                    </span>
                                                                </template>
                                                            </v-file-input>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                            </v-tab-item>
                                            <v-tab-item value="tab-2">
                                                <v-row class="mt-4">
                                                    <v-col class="mx-4">
                                                        <validation-provider
                                                            v-slot="{ errors }"
                                                            name="Order Number"
                                                        >
                                                            <v-text-field
                                                                v-model="orderNumber"
                                                                maxLength="12"
                                                                :readonly="attachment !== undefined"
                                                                label="Order No"
                                                                rounded
                                                                outlined
                                                                :error-messages="errors"
                                                                counter="12"
                                                                @keyup="orderNumberInput"
                                                            />
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-sheet>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="mx-4">
                                <v-textarea
                                    hide-details
                                    readonly
                                    :value="organizationWorkshopMessage"
                                    rounded
                                    outlined
                                    label="Message"
                                    rows="3"
                                    auto-grow
                                />
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-else>
                        <v-col class="mx-4 mt-4">
                            <v-textarea
                                v-model="quoteMessage"
                                label="Message to workshop"
                                counter="300"
                                rows="3"
                                hint="Optional"
                                outlined
                                no-resize
                                auto-grow
                                persistent-hint
                                maxLength="300"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-spacer />
                        <v-col cols="12" sm="8">
                            <v-switch
                                v-if="
                                    quoteModel.quoteStatus === quoteStatuses.pendingApproval.id
                                        && quoteModel.isExpired !== true
                                "
                                v-model="terms"
                                class="ml-4"
                            >
                                <template #label>
                                    <div>
                                        I agree to the
                                        <v-tooltip top>
                                            <template #activator="{ on }">
                                                <a target="_blank" href="/terms" @click.stop v-on="on">
                                                    terms and conditions.
                                                </a>
                                            </template>
                                            Opens in a new window
                                        </v-tooltip>
                                    </div>
                                </template>
                            </v-switch>
                        </v-col>
                        <v-spacer />
                    </v-row>
                </v-sheet>
                <v-divider />
                <v-card-actions>
                    <v-row class="my-1">
                        <v-col>
                            <v-btn
                                color="darkGrey"
                                rounded
                                outlined
                                class="smc-medium-button"
                                @click="confirmDialog = false"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="primary"
                                rounded
                                class="smc-medium-button"
                                @click="acceptQuoteConfirmed"
                                :loading="quoteAcceptLoading"
                                :disabled="!terms"
                            >
                                Accept
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="amendmentDialog"
            persistent
            content-class="smc-dialog"
            :width="$vuetify.breakpoint.smAndUp ? '550' : '100vw'"
        >
            <v-card class="smc-card-dialog" color="white">
                <v-card-title
                    class="primary white--text text-center smc-card-dialog-title smc-card-dialog-title-narrow"
                >
                    <span class="mx-auto">CONFIRM</span>
                </v-card-title>
                <div class="smc-card-top-bar">
                    <v-btn
                        icon
                        color="midGrey"
                        class="float-right"
                        height="32"
                        width="32"
                        @click="amendmentDialog = false"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </div>
                <v-sheet class="smc-card-dialog-content-sheet">
                    <v-card-text>
                        <p>
                            Are you sure you'd like to continue ?
                            <br />
                            <strong>
                                {{ quoteModel.workshop.name }}
                            </strong>
                            ?
                        </p>
                        <v-row>
                            <v-col cols="6">
                                <v-img
                                    :src="quoteModel.workshop.logoUrl"
                                    :lazy-src="quoteModel.workshop.logoUrl"
                                />
                            </v-col>
                            <v-col cols="6" class="position-relative">
                                <span
                                    class="quote-total primary--text title font-weight-bold position-absolute horizontal-vertical-center"
                                >
                                    {{ getQuoteTotal() | currency }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-divider class="my-2" />
                        <v-row>
                            <v-col class="text-left">
                                <h3>Approved:</h3>
                                <ul>
                                    <li
                                        v-for="(item, index) in getAmendments().approved"
                                        :key="index"
                                    >
                                        {{ item.name }}
                                    </li>
                                </ul>
                            </v-col>
                            <v-col class="text-left">
                                <h3>Declined:</h3>
                                <ul>
                                    <li
                                        v-for="(item, index) in getAmendments().declined"
                                        :key="index"
                                    >
                                        {{ item.name }}
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>
                        <div v-if="isOrganization">
                            <v-row>
                                <v-col>
                                    <v-card>
                                        <v-sheet class="order-tab-container">
                                            <v-tabs
                                                v-model="orderCaptureTab"
                                                fixed-tabs
                                                center-active
                                                centered
                                                background-color="primary"
                                                active-class="white red--text border pa-2"
                                                slider-color="blue"
                                            >
                                                <v-tabs-slider />

                                                <v-tab href="#tab-1">
                                                    Attach File
                                                    <v-spacer />
                                                    <v-icon>mdi-file-outline</v-icon>
                                                </v-tab>

                                                <v-tab href="#tab-2">
                                                    Manual Order No
                                                    <v-spacer />
                                                    <v-icon>mdi-numeric-9-box-multiple-outline</v-icon>
                                                </v-tab>
                                            </v-tabs>

                                            <v-tabs-items v-model="orderCaptureTab">
                                                <v-tab-item value="tab-1">
                                                    <v-row class="mt-4">
                                                        <v-col class="mx-4">
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="Attach Order"
                                                                id="noteAttachment"
                                                                :rules="`ext:pdf|size:5000`"
                                                            >
                                                                <v-file-input
                                                                    v-model="attachment"
                                                                    :error-messages="errors"
                                                                    label="Attach Order"
                                                                    prepend-inner-icon="mdi-paperclip"
                                                                    prepend-icon
                                                                    outlined
                                                                    rounded
                                                                    counter
                                                                    show-size
                                                                    accept="application/pdf"
                                                                >
                                                                    <template #selection="{ index, text }">
                                                                        <v-chip
                                                                            v-if="index < 2"
                                                                            color="primary accent-4"
                                                                            dark
                                                                            label
                                                                            small
                                                                        >
                                                                            {{ text }}
                                                                        </v-chip>
                                                                        <span
                                                                            v-else-if="index === 2"
                                                                            class="overline grey--text text--darken-3 mx-2"
                                                                        >
                                                                            +{{ files.length - 2 }} File(s)
                                                                        </span>
                                                                    </template>
                                                                </v-file-input>
                                                            </validation-provider>
                                                        </v-col>
                                                    </v-row>
                                                </v-tab-item>
                                                <v-tab-item value="tab-2">
                                                    <v-row class="mt-4">
                                                        <v-col class="mx-4">
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="Order Number"
                                                            >
                                                                <v-text-field
                                                                    v-model="orderNumber"
                                                                    maxLength="12"
                                                                    :readonly="attachment !== undefined"
                                                                    label="Order No"
                                                                    rounded
                                                                    outlined
                                                                    :error-messages="errors"
                                                                    counter="12"
                                                                    @input="orderNumberInput"
                                                                />
                                                            </validation-provider>
                                                        </v-col>
                                                    </v-row>
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </v-sheet>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="mx-4">
                                    <v-textarea
                                        hide-details
                                        readonly
                                        :value="organizationWorkshopMessage"
                                        rounded
                                        outlined
                                        label="Message"
                                        rows="3"
                                        auto-grow
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <v-row v-else>
                            <v-col>
                                <v-textarea
                                    v-model="quoteMessage"
                                    label="Message to workshop"
                                    counter="300"
                                    rows="3"
                                    hint="Optional"
                                    outlined
                                    no-resize
                                    auto-grow
                                    persistent-hint
                                    maxLength="300"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-sheet>
                <v-divider />
                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-btn
                                color="darkGrey"
                                rounded
                                outlined
                                class="smc-medium-button"
                                @click="amendmentDialog = false"
                            >
                                No
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="primary"
                                rounded
                                class="smc-medium-button"
                                @click="acceptAmendmentConfirmed"
                                :loading="quoteAcceptLoading"
                            >
                                Yes
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="newAmendmentDialog"
            persistent
            content-class="smc-dialog"
        >
            <v-card
                class="smc-card-dialog"
                color="white"
                v-if="policyQuoteProp === quoteTypes.igsPolicyLabel"
            >
                <v-card-title
                    class="primary white--text text-center smc-card-dialog-title smc-card-dialog-title-narrow"
                >
                    <span class="mx-auto">Please note</span>
                </v-card-title>
                <div class="smc-card-top-bar">
                    <v-btn
                        icon
                        color="midGrey"
                        class="float-right"
                        height="32"
                        width="32"
                        @click="newAmendmentDialog = false"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </div>
                <v-card-subtitle>
                    <v-row align="center">
                        <v-col class="shrink">
                            <v-icon color="primary" x-large>mdi-alert-circle</v-icon>
                        </v-col>
                        <v-col>
                            <p>
                                The changes requested by the workshop are not covered under your
                                policy and will be for your own account.
                            </p>
                            <p>
                                This change will only be accepted with your consent. Please
                                select the "Confirm" button if you agree with the change.
                            </p>
                        </v-col>
                    </v-row>
                </v-card-subtitle>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        class="smc-medium-button"
                        rounded
                        @click="newAmendmentDialog = false"
                    >
                        Got it
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
            <v-card class="smc-card-dialog" color="white" v-else>
                <v-card-title
                    class="primary white--text text-center smc-card-dialog-title smc-card-dialog-title-narrow"
                >
                    <span class="mx-auto">Please note</span>
                </v-card-title>
                <div class="smc-card-top-bar">
                    <v-btn
                        icon
                        color="midGrey"
                        class="float-right"
                        height="32"
                        width="32"
                        @click="newAmendmentDialog = false"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </div>
                <v-card-subtitle>
                    <v-row align="center">
                        <v-col class="shrink">
                            <v-icon color="primary" x-large>mdi-alert-circle</v-icon>
                        </v-col>
                        <v-col>
                            No changes will be accepted or declined until you select the
                            "Confirm" button at the bottom of the page.
                        </v-col>
                    </v-row>
                </v-card-subtitle>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        class="smc-medium-button"
                        rounded
                        @click="newAmendmentDialog = false"
                    >
                        Got it
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="quoteOutOfDateDialog"
            persistent
            content-class="smc-dialog"
        >
            <v-card class="smc-card-dialog">
                <v-card-title class="primary white--text smc-card-dialog-title">
                    <span class="mx-auto">Please note</span>
                </v-card-title>
                <div class="smc-card-top-bar"></div>
                <v-card-subtitle class="mt-0 title">
                    This response is out of date.
                </v-card-subtitle>
                <v-card-subtitle>
                    We've detected a change submitted to this response by the workshop.
                    We'll reload the response with all the updates included. Please
                    inspect the response once again before accepting.
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        class="smc-medium-button"
                        rounded
                        @click="reloadPage()"
                    >
                        Got it
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="workshopMessageDialog"
            persistent
            content-class="smc-dialog"
        >
            <validation-observer ref="workshop-message-validation-observer">
                <v-form lazy-validation>
                    <v-card class="smc-card-dialog">
                        <v-card-title class="primary white--text smc-card-dialog-title">
                            <span class="mx-auto">Message</span>
                        </v-card-title>
                        <div class="smc-card-top-bar">
                            <v-btn
                                icon
                                color="midGrey"
                                class="float-right"
                                height="32"
                                width="32"
                                @click="workshopMessageDialog = false"
                            >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </div>
                        <v-sheet class="smc-card-dialog-content-sheet">
                            <v-card-text>
                                <p>
                                    Send a message to
                                    <strong>{{ quoteModel.workshop.name }}</strong>
                                    ?
                                </p>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        offset="0"
                                        :offset-sm="hidePriceProp ? 3 : 0"
                                    >
                                        <v-img
                                            :src="quoteModel.workshop.logoUrl"
                                            :lazy-src="quoteModel.workshop.logoUrl"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        class="position-relative"
                                        v-if="!hidePriceProp"
                                    >
                                        <span
                                            class="primary--text title font-weight-bold position-absolute horizontal-vertical-center quote-total"
                                        >
                                            {{ getQuoteTotal() | currency }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-row>
                                <v-col class="mx-4 mt-4">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Note"
                                        id="workshopNote"
                                        rules="required"
                                    >
                                        <v-textarea
                                            :id="`workshopMessage-note`"
                                            v-model="quoteMessage"
                                            :error-messages="errors"
                                            label="Note to workshop"
                                            counter="160"
                                            rows="3"
                                            outlined
                                            no-resize
                                            auto-grow
                                            persistent-hint
                                            maxLength="160"
                                        />
                                    </validation-provider>
                                </v-col>
                            </v-row>

                            <v-card-text v-if="workshopContactPhone">
                                <v-divider />
                                <v-row class="mt-0 mb-1">
                                    <v-spacer />
                                    <v-col cols="6">
                                        <v-checkbox
                                            class="mt-0"
                                            hide-details
                                            v-model="redirectWhatsApp"
                                        >
                                            <template #label>
                                                Forward to
                                                <span class="success--text ml-2">
                                                    <v-icon color="success">mdi-whatsapp</v-icon>WhatsApp
                                                </span>
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                    <v-spacer />
                                </v-row>
                                <v-divider />
                                <v-expand-transition>
                                    <v-row v-if="redirectWhatsApp">
                                        <v-col>
                                            <v-textarea
                                                :value="workshopWhatsappMessage"
                                                readonly
                                                outlined
                                                rows="3"
                                                no-resize
                                                auto-grow
                                                hide-details
                                            />
                                        </v-col>
                                    </v-row>
                                </v-expand-transition>
                            </v-card-text>
                            <v-card flat>
                                <v-card-title
                                    class="smc-card-reduced-heading primary white--text mb-4"
                                >
                                    Attachments
                                    <v-btn
                                        style="position: absolute; right: 5px"
                                        width="150"
                                        color="secondary"
                                        @click="addAttachment"
                                        small
                                        rounded
                                    >
                                        Add attachment
                                    </v-btn>
                                </v-card-title>
                                <v-card-subtitle class="pt-3">
                                    You can attach files such as photos or PDFs to send to the
                                    workshop.
                                </v-card-subtitle>
                                <v-card-text>
                                    <!-- TODO fix lint error -->
                                    <!-- eslint-disable vue/no-template-shadow -->
                                    <v-sheet
                                        v-for="(attachment, index) in attachments"
                                        :key="index"
                                    >
                                        <v-row>
                                            <v-col>
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Attachment"
                                                    id="noteAttachment"
                                                    rules="required|ext:jpeg,jpg,gif,png,svg,pdf|size:8000"
                                                >
                                                    <v-file-input
                                                        :id="`createQuote-attachment-${index}`"
                                                        v-model="attachment.file"
                                                        :error-messages="errors"
                                                        label="Attachment"
                                                        prepend-inner-icon="mdi-paperclip"
                                                        prepend-icon
                                                        outlined
                                                        rounded
                                                        counter
                                                        show-size
                                                        accept="image/*,application/pdf"
                                                    >
                                                        <!-- TODO fix lint error -->
                                                        <!-- eslint-disable vue/no-template-shadow -->
                                                        <template #selection="{ index, text }">
                                                            <v-chip
                                                                v-if="index < 2"
                                                                color="primary accent-4"
                                                                dark
                                                                label
                                                                small
                                                            >
                                                                {{ text }}
                                                            </v-chip>
                                                            <span
                                                                v-else-if="index === 2"
                                                                class="overline grey--text text--darken-3 mx-2"
                                                            >
                                                                +{{ files.length - 2 }} File(s)
                                                            </span>
                                                        </template>
                                                    </v-file-input>
                                                </validation-provider>
                                            </v-col>
                                            <v-col class="shrink pt-5">
                                                <v-btn
                                                    fab
                                                    small
                                                    color="red"
                                                    @click="deleteAttachment(attachment)"
                                                >
                                                    <v-icon class="white--text">mdi-delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-card-text>
                            </v-card>
                        </v-sheet>
                        <v-divider />
                        <v-card-actions>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        color="darkGrey"
                                        rounded
                                        outlined
                                        class="smc-medium-button"
                                        @click="workshopMessageDialog = false"
                                    >
                                        Cancel
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        color="primary"
                                        rounded
                                        class="smc-medium-button"
                                        @click="
                                            submitWorkshopMessage(
                                                'workshop-message-validation-observer'
                                            )
                                        "
                                        :loading="workshopMessageLoading"
                                    >
                                        Submit
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </validation-observer>
        </v-dialog>

        <v-dialog v-model="orgOrderDialog" persistent content-class="smc-dialog">
            <validation-observer ref="organization-order-validation-observer">
                <v-form lazy-validation>
                    <v-card class="smc-card-dialog">
                        <v-card-title class="primary white--text smc-card-dialog-title">
                            <span class="mx-auto">Order</span>
                        </v-card-title>
                        <div class="smc-card-top-bar">
                            <v-btn
                                icon
                                color="midGrey"
                                class="float-right"
                                height="32"
                                width="32"
                                @click="orgOrderDialog = false"
                            >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </div>
                        <v-sheet class="smc-card-dialog-content-sheet">
                            <v-card-text>
                                <p>
                                    Attach order for
                                    <strong>{{ quoteModel.workshop.name }}</strong>
                                    ?
                                </p>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        offset="0"
                                        :offset-sm="hidePriceProp ? 3 : 0"
                                    >
                                        <v-img
                                            :src="quoteModel.workshop.logoUrl"
                                            :lazy-src="quoteModel.workshop.logoUrl"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        class="position-relative"
                                        v-if="!hidePriceProp"
                                    >
                                        <span
                                            class="primary--text title font-weight-bold position-absolute horizontal-vertical-center quote-total"
                                        >
                                            {{ getQuoteTotal() | currency }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-row class="mt-4">
                                <v-col class="mx-4">
                                    <v-card>
                                        <v-sheet class="order-tab-container">
                                            <v-tabs
                                                v-model="orderCaptureTab"
                                                fixed-tabs
                                                center-active
                                                centered
                                                background-color="primary"
                                                active-class="white red--text border pa-2"
                                                slider-color="blue"
                                            >
                                                <v-tabs-slider />

                                                <v-tab href="#tab-1">
                                                    Attach File
                                                    <v-spacer />
                                                    <v-icon>mdi-file-outline</v-icon>
                                                </v-tab>

                                                <v-tab href="#tab-2">
                                                    Manual Order No
                                                    <v-spacer />
                                                    <v-icon>mdi-numeric-9-box-multiple-outline</v-icon>
                                                </v-tab>
                                            </v-tabs>

                                            <v-tabs-items v-model="orderCaptureTab">
                                                <v-tab-item value="tab-1">
                                                    <v-row class="mt-4">
                                                        <v-col class="mx-4">
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="Attach Order"
                                                                id="noteAttachment"
                                                                :rules="`ext:pdf|size:5000${
                                                                    orderCaptureTab === 'tab-1' ? '|required' : ''
                                                                }`"
                                                            >
                                                                <v-file-input
                                                                    v-model="attachment"
                                                                    :error-messages="errors"
                                                                    label="Attach Order"
                                                                    prepend-inner-icon="mdi-paperclip"
                                                                    prepend-icon
                                                                    outlined
                                                                    rounded
                                                                    counter
                                                                    show-size
                                                                    accept="application/pdf"
                                                                >
                                                                    <template #selection="{ index, text }">
                                                                        <v-chip
                                                                            v-if="index < 2"
                                                                            color="primary accent-4"
                                                                            dark
                                                                            label
                                                                            small
                                                                        >
                                                                            {{ text }}
                                                                        </v-chip>
                                                                        <span
                                                                            v-else-if="index === 2"
                                                                            class="overline grey--text text--darken-3 mx-2"
                                                                        >
                                                                            +{{ files.length - 2 }} File(s)
                                                                        </span>
                                                                    </template>
                                                                </v-file-input>
                                                            </validation-provider>
                                                        </v-col>
                                                    </v-row>
                                                </v-tab-item>
                                                <v-tab-item value="tab-2">
                                                    <v-row class="mt-4">
                                                        <v-col class="mx-4">
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="Order Number"
                                                                rules="required"
                                                            >
                                                                <v-text-field
                                                                    v-model="orderNumber"
                                                                    maxLength="12"
                                                                    :readonly="attachment !== undefined"
                                                                    label="Order No"
                                                                    rounded
                                                                    outlined
                                                                    :error-messages="errors"
                                                                    counter="12"
                                                                    @keyup="orderNumberInput"
                                                                />
                                                            </validation-provider>
                                                        </v-col>
                                                    </v-row>
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </v-sheet>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-sheet>
                        <v-divider />
                        <v-card-actions>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        color="darkGrey"
                                        rounded
                                        outlined
                                        class="smc-medium-button"
                                        @click="orgOrderDialog = false"
                                    >
                                        Cancel
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        color="primary"
                                        rounded
                                        class="smc-medium-button"
                                        @click="
                                            submitSalesOrder('organization-order-validation-observer')
                                        "
                                        :loading="workshopMessageLoading"
                                    >
                                        Submit
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </validation-observer>
        </v-dialog>

        <v-dialog
            v-if="quoteRequestOrganizationShareProp"
            v-model="quoteRequestDifferentShareDialog"
            content-class="smc-dialog"
        >
            <v-card class="smc-card-dialog">
                <v-card-title class="primary white--text smc-card-dialog-title">
                    <span class="mx-auto">Copy Link</span>
                </v-card-title>
                <div class="smc-card-top-bar">
                    <v-btn
                        icon
                        color="midGrey"
                        class="float-right"
                        height="32"
                        width="32"
                        @click="quoteRequestDifferentShareDialog = false"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </div>
                <v-sheet class="smc-card-dialog-content-sheet">
                    <v-card-text>
                        <p>
                            Previously the quote from
                            <strong>
                                {{ quoteRequestOrganizationShareProp.workshopName }}
                            </strong>
                            was shared.
                        </p>
                        <v-row>
                            <v-col cols="6" offset="3">
                                <v-img
                                    :src="quoteRequestOrganizationShareProp.workshopLogoUrl"
                                    :lazy-src="quoteRequestOrganizationShareProp.workshopLogoUrl"
                                />
                            </v-col>
                        </v-row>
                        <p class="mt-6">
                            Are you sure you want to share a quote from a different workshop?
                        </p>
                    </v-card-text>
                </v-sheet>
                <v-divider />
                <v-card-actions>
                    <v-row>
                        <v-col class="text-right">
                            <v-btn
                                color="darkGrey"
                                rounded
                                outlined
                                class="smc-medium-button"
                                @click="quoteRequestDifferentShareDialog = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                class="mx-2"
                                rounded
                                color="primary"
                                @click="copyQuoteViewer(true)"
                            >
                                Share Quote
                            </v-btn>
                            <v-snackbar
                                app
                                color="primary"
                                v-model="copySnackbar"
                                :timeout="3000"
                                centered
                            >
                                Shareable quote link copied to clipboard
                            </v-snackbar>
                            <v-btn color="primary" rounded @click="openQuoteViewer(true)">
                                Open/Print Quote
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="submitNexusDialog" persistent content-class="smc-dialog">
            <v-card class="smc-card-dialog">
                <v-card-title class="primary white--text smc-card-dialog-title">
                    <span class="mx-auto">Confirm</span>
                </v-card-title>
                <div class="smc-card-top-bar">
                    <v-btn
                        icon
                        color="midGrey"
                        class="float-right"
                        height="32"
                        width="32"
                        @click="submitNexusDialog = false"
                    >
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    Are you sure you want to share this quote with Nexus?
                </v-card-text>
                <v-expand-transition>
                    <v-alert v-if="submitNexusError" text color="red" border="left">
                        Submitting to Nexus failed.
                    </v-alert>
                </v-expand-transition>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        v-if="submitNexusError"
                        color="error"
                        rounded
                        @click="submitNexusDialog = false"
                    >
                        Close
                    </v-btn>
                    <div v-else>
                        <v-btn
                            class="mr-2"
                            color="error"
                            rounded
                            outlined
                            @click="submitNexusDialog = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn color="error" rounded @click="submitNexus"> Submit </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import _ from 'lodash';
import Quote from '@/models/quote';
import Address from '@/models/address';
import User from '@/models/user';
import QuoteAccept from '@/models/quote-accept';
import QuoteAmendmentAccept from '@/models/quote-amendment-accept';
import QuoteRequest from '@/models/quote-request';
import QuoteRequestOrganizationBranchShare from '@/models/quote-request-organization-branch-share';
import {
    quoteTypes,
    dateHelper,
    addressHelper,
    quoteStatuses,
    amendmentStatuses,
    amendmentTypes,
    phoneHelper,
} from '@/helpers';
import { quoteService, paymentService, quoteRequestService } from '@/services';
import addressView from '@/components/widgets/address-view';
import addressRouteView from '@/components/widgets/address-route-view';
import quoteDetailsView from '@/components/quote-details-view';
import accountLogin from '@/components/account/account-login';
import accountForgotPassword from '@/components/account/account-forgot-password';

import analytics from '@/mixins/analytics';
import validate from '@/mixins/validator';
import quotePdf from '@/mixins/quote-pdf';

export default {
    name: 'quote-request-view',
    mixins: [analytics, validate, quotePdf],
    props: {
        addressProp: Address,
        quoteProp: Quote,
        userProp: User,
        quoteRequestProp: QuoteRequest,
        userEmailProp: String,
        quoteCountProp: Number,
        smcReferenceProp: String,
        hidePriceProp: Boolean,
        policyQuoteProp: String,
        quoteRequestOrganizationShareProp: QuoteRequestOrganizationBranchShare,
    },
    data: () => {
        return {
            shareLoading: false,
            pdfLoading: false,

            terms: false,
            quoteModel: undefined,
            quoteStatuses,
            amendmentStatuses,
            amendmentTypes,
            user: undefined,
            dateHelper: dateHelper,
            quoteTypes,
            addressDialog: false,
            routeDialog: false,
            reviewDialog: false,
            loginDialog: false,
            forgotPasswordDialog: false,
            confirmDialog: false,
            amendmentDialog: false,
            newAmendmentDialog: false,
            quoteAcceptLoading: false,
            quoteOutOfDateDialog: false,
            submitNexusDialog: false,
            submitNexusError: false,

            copySnackbar: false,
            sharedSnackbar: false,

            quoteRequestDifferentShareDialog: false,

            orderCaptureTab: null,
            orderNumber: '',
            orderPrefix: ' - ORDER-',
            workshopMessageDialog: false,
            redirectWhatsApp: false,
            workshopMessageLoading: false,
            orgOrderDialog: false,
            orgOrderLoading: false,

            amendmentRequiredErrors: [],
            quotePanel: [],
            quoteMessage: '',
            attachment: undefined,
            attachments: [],
        };
    },
    components: {
        addressView,
        addressRouteView,
        quoteDetailsView,
        accountLogin,
        accountForgotPassword,
    },
    computed: {
        isInvoice() {
            return (
                this.quoteModel.quoteStatus === this.quoteStatuses.invoiced.id
                || this.quoteModel.quoteStatus === this.quoteStatuses.completed.id
            );
        },
        isPaidInvoice() {
            return (
                this.quoteModel.quoteStatus === this.quoteStatuses.completed.id
                || this.quoteModel.quoteStatus === this.quoteStatuses.paid.id
            );
        },
        showRatings() {
            return process.env.VUE_APP_SHOW_WORKSHOP_RATINGS;
        },
        currentUserFirstname() {
            if (this.$store.getters['user/isAuthenticated']) {
                return this.$store.getters['user/getFirstName'];
            }
            return '';
        },
        currentUserSurname() {
            if (this.$store.getters['user/isAuthenticated']) {
                return this.$store.getters['user/getSurname'];
            }
            return '';
        },
        currentUserEmail() {
            if (this.$store.getters['user/isAuthenticated']) {
                return this.$store.getters['user/getEmail'];
            }
            return '';
        },
        consumablesItem() {
            return _.filter(this.quoteModel.quoteItems, item => {
                return (
                    item.description === 'Consumables' && item.quoteRequestItemId === null
                );
            });
        },
        adminFeeItem() {
            return _.filter(this.quoteModel.quoteItems, item => {
                return (
                    item.description === 'Admin Fee' && item.quoteRequestItemId === null
                );
            });
        },
        isOrganization() {
            if (this.$store.getters['user/isAuthenticated']) {
                return this.$store.getters['user/isOrganization'];
            }
            return false;
        },
        hasBusinessAddress() {
            if (this.$store.getters['user/isAuthenticated']) {
                return this.$store.getters['user/hasBusinessAddress'];
            }
            return false;
        },
        customerBusinessAddress() {
            if (this.$store.getters['user/isAuthenticated']) {
                return this.$store.getters['user/getBusinessAddressString'];
            }
            return null;
        },
        shareLink() {
            return `${process.env.VUE_APP_QUOTE_VIEWER_URL}/quote?quoteId=${this.quoteModel.uniqueId}`;
        },
        organizationWorkshopMessage() {
            let parts = [];

            if (this.quoteModel.quoteStatus === quoteStatuses.pendingApproval.id) {
                parts.push(
                    `Quote ${
                        this.quoteRequestProp.reference
                    } approved R${this.getQuoteTotal().toFixed(2)}`,
                );
            }
            else if (
                this.quoteModel.quoteStatus === quoteStatuses.amendmentPending.id
            ) {
                let amendmentMovement = 0;
                let amendments = this.getAmendments();
                if (amendments.approved.length === 0) {
                    parts.push('Price Amendment Declined');
                }
                else {
                    amendments.approved.forEach(item => {
                        item.quoteItems.forEach(quoteItem => {
                            let movement = quoteItem.totalIncl - quoteItem.verifiedTotalIncl;
                            amendmentMovement += movement;
                        });
                    });
                    parts.push(
                        `Price Amendment Approved R${amendmentMovement.toFixed(2)}`,
                    );
                }
            }

            if (this.orderNumber) {
                parts.push(`- ORDER-${this.orderNumber}`);
            }

            return _.join(parts, ' ');
        },
        quoteTime() {
            return (
                dateHelper.convertToDate(this.quoteProp.dateCreated)
                - dateHelper.convertToDate(this.quoteRequestProp.dateCreated)
            );
        },
        workshopContactPhone() {
            if (
                this.quoteProp.workshop
                && this.quoteProp.workshop.workshopContact
                && this.quoteProp.workshop.workshopContact
            ) {
                return this.quoteProp.workshop.workshopContact.phone;
            }
            return null;
        },
        workshopWhatsappMessage() {
            return `${this.smcReferenceProp}: ${this.quoteMessage}`;
        },
        organizationSubmittedForApproval() {
            if (
                this.quoteRequestOrganizationShareProp === null
                || this.quoteRequestOrganizationShareProp.quoteId !== this.quoteModel.id
                || this.quoteModel.quoteStatus !== quoteStatuses.pendingApproval.id
            ) {
                return null;
            }

            return this.quoteRequestOrganizationShareProp.isSuccess;
        },
    },
    created() {
        let vm = this;
        this.quoteModel = _.cloneDeep(this.quoteProp);
        _.forEach(this.quoteModel.quoteRequestItems, function (item) {
            if (vm.hasPendingAmendment(item)) {
                item.amendmentAccepted = null;
            }
        });

        if (this.$store.getters['user/isOrganization']) {
            this.quoteModel.isExpired = false;
        }

        this.newAmendmentDialog = _.some(this.quoteModel.quoteItems, {
            amendmentStatus: this.amendmentStatuses.pending,
        });

        if (this.quoteModel.quoteStatus !== quoteStatuses.pendingApproval.id) {
            this.showPanel();
        }

        if (this.$route.query.openMessage) {
            this.openWorkshopMessageDialog();
            this.newAmendmentDialog = false;
            // Remove `openMessage` from the URL query string
            const query = { ...this.$route.query };
            delete query.openMessage;
            this.$router.replace({ query });
        }
        else if (this.$route.query.openOrder) {
            this.openOrgOrderDialog();
            this.newAmendmentDialog = false;
            // Remove `openOrder` from the URL query string
            const query = { ...this.$route.query };
            delete query.openOrder;
            this.$router.replace({ query });
        }
    },
    watch: {
        attachment(newVal) {
            if (newVal && newVal.name) {
                this.orderNumber = newVal.name.split('.')[0];
            }
            else {
                this.orderNumber = '';
            }
        },
        orderCaptureTab() {
            this.attachment = undefined;
            this.orderNumber = '';
        },
        amendmentDialog(newVal) {
            if (!newVal) {
                this.attachment = undefined;
            }
        },
        workshopMessageDialog(newVal) {
            if (!newVal) {
                this.quoteMessage = '';
                this.attachment = undefined;
            }
        },
        orgOrderDialog(newVal) {
            if (!newVal) {
                this.quoteMessage = '';
                this.attachment = undefined;
            }
        },
    },
    methods: {
        callback: function (response) {
            this.trackEvent('PayInvoice', 'Quote', response.status);
            if (response.status === 'success') {
                this.$router.push({
                    name: 'status',
                });
            }
        },
        orderNumberInput: function () {
            this.orderNumber = this.orderNumber
                .toUpperCase()
                .replace(/[^A-Z0-9]/g, '');
        },
        async payInvoice() {
            this.trackEvent('PayInvoice', 'Quote', '');
            await paymentService.pay(this.quoteModel.quoteRequestId);
        },
        showPanel() {
            this.quotePanel = 0;
        },
        hidePanel(scroll) {
            if (scroll) {
                let vm = this;
                this.$vuetify.goTo(`#quote-heading-${this.quoteModel.id}`);
                setTimeout(() => {
                    vm.quotePanel = [];
                }, 500);
            }
            else {
                this.quotePanel = [];
            }
        },
        toggleQuotePanel() {
            if (this.quotePanel === 0) {
                this.hidePanel();
            }
            else {
                this.showPanel();
            }
        },
        getQuoteTotal() {
            //return this.quoteModel.quoteTotalIncl;
            let vm = this;
            let total = _.sumBy(this.quoteModel.quoteRequestItems, function (item) {
                return vm.getQuoteRequestItemTotal(item);
            });
            if (this.consumablesItem[0]) {
                total += _.get(this.consumablesItem, '[0].totalIncl');
            }
            if (this.adminFeeItem[0]) {
                total += _.get(this.adminFeeItem, '[0].totalIncl');
            }
            return total;
        },
        getQuoteRequestItemTotal(quoteRequestItem) {
            let sum = _.sumBy(quoteRequestItem.quoteItems, function (item) {
                if (
                    item.amendmentStatus === amendmentStatuses.pending
                    || item.amendmentStatus === amendmentStatuses.approved
                    || item.amendmentStatus === null
                ) {
                    if (item.amendmentType !== amendmentTypes.removed) {
                        if (
                            item.amendmentStatus === amendmentStatuses.pending
                            && quoteRequestItem.amendmentAccepted
                        ) {
                            return item.totalIncl;
                        }
                        else if (
                            item.amendmentStatus === amendmentStatuses.pending
                            && !quoteRequestItem.amendmentAccepted
                        ) {
                            return item.verifiedTotalIncl;
                        }
                        else {
                            return item.totalIncl;
                        }
                    }
                }
            });

            if (quoteRequestItem.labourItem) {
                if (
                    quoteRequestItem.labourItem.amendmentType
                    !== amendmentTypes.removed
                    && (quoteRequestItem.labourItem.amendmentStatus === null
                        || quoteRequestItem.labourItem.amendmentStatus
                        === amendmentStatuses.approved)
                ) {
                    if (!quoteRequestItem.labourItem.verifiedTotalIncl) {
                        let labourTotal = quoteRequestItem.labourItem.quantity
                            * quoteRequestItem.labourItem.priceIncl;

                        // Apply discount only if verifiedTotalExcl is initially undefined
                        const labourDiscountMultiplier = 1 - (quoteRequestItem.labourItem.discountPerc || 0) / 100;
                        labourTotal *= labourDiscountMultiplier;

                        sum += labourTotal;
                    }
                    else {
                        //sum += quoteRequestItem.labourItem.verifiedTotalIncl;
                        let labourTotal = quoteRequestItem.labourItem.quantity
                            * quoteRequestItem.labourItem.priceIncl;

                        // Apply discount only if verifiedTotalExcl is initially undefined
                        const labourDiscountMultiplier = 1 - (quoteRequestItem.labourItem.discountPerc || 0) / 100;
                        labourTotal *= labourDiscountMultiplier;

                        sum += labourTotal;
                    }
                }
            }

            return sum;
        },
        printInstructions(id) {
            this.$htmlToPaper(id);
        },
        acceptQuote() {
            if (this.$store.getters['user/isAuthenticated']) {
                this.confirmDialog = true;
            }
            else {
                this.loginDialog = true;
            }
        },
        updateQuote(quote) {
            this.quoteModel = quote;
        },
        getAmendments() {
            return {
                approved: _.filter(this.quoteModel.quoteRequestItems, {
                    amendmentAccepted: true,
                }),
                declined: _.filter(this.quoteModel.quoteRequestItems, {
                    amendmentAccepted: false,
                }),
            };
        },
        confirmAmendment() {
            this.amendmentRequiredErrors = [];
            this.quoteModel.quoteRequestItems.forEach(item => {
                if (this.hasPendingAmendment(item)) {
                    if (item.amendmentAccepted === null) {
                        this.amendmentRequiredErrors.push(item.name);
                    }
                }
            });
            if (this.amendmentRequiredErrors.length === 0) {
                this.amendmentDialog = true;
            }
        },
        openWorkshopMessageDialog() {
            if (
                this.quoteModel.quoteMessages === undefined
                || this.quoteModel.quoteMessages === null
                || this.quoteModel.quoteMessages.length < 10
            ) {
                this.workshopMessageDialog = true;
            }
        },
        openOrgOrderDialog() {
            if (
                this.quoteModel.quoteMessages === undefined
                || this.quoteModel.quoteMessages === null
                || this.quoteModel.quoteMessages.length < 10
            ) {
                this.orgOrderDialog = true;
            }
        },
        async submitWorkshopMessage(validationObserver) {
            if (await this.$refs[validationObserver].validate()) {
                this.workshopMessageLoading = true;
                let user = this.$store.getters['user/getUserModel'];

                let attachments = [];
                if (this.attachment) {
                    attachments.push(attachments);
                }
                if (this.attachments && this.attachments.length > 0) {
                    attachments.push(..._.map(this.attachments, 'file'));
                }

                let result = await quoteService.messageWorkshop(
                    new QuoteAccept(
                        this.quoteModel.id,
                        user,
                        this.quoteModel.dateModifiedUtc,
                        this.quoteMessage,
                        this.orderNumber,
                    ),
                    attachments,
                );

                if (result.status === 200) {
                    if (this.redirectWhatsApp) {
                        let phoneNumber = this.workshopContactPhone;
                        if (_.startsWith(phoneNumber, '0')) {
                            phoneNumber = phoneHelper.addPhonePrefix(phoneNumber);
                        }

                        phoneNumber = _.replace(phoneNumber, / /g, '');
                        let message = _.replace(
                            this.workshopWhatsappMessage,
                            /\n/g,
                            '%0A%0A',
                        );

                        let url = `https://wa.me/${phoneNumber}?text=${message}`;
                        window.open(url, '_blank');
                    }

                    this.$router.go(this.$router.currentRoute);
                }

                this.workshopMessageLoading = false;
            }
        },
        async submitSalesOrder(validationObserver) {
            if (this.attachment === null) {
                this.quoteMessage = `SALES ORDER NUMBER CAPTURED - ${this.orderNumber}`;
            }
            else {
                this.quoteMessage = `SALES ORDER ATTACHED - ${this.orderNumber}`;
            }
            await this.submitWorkshopMessage(validationObserver);
        },
        async acceptQuoteConfirmed() {
            this.quoteAcceptLoading = true;

            let user = this.$store.getters['user/getUserModel'];
            user.businessAddress = addressHelper.objectifyAddress(
                user.businessAddressString,
            );
            user.businessAddress.id = 0;

            if (this.isOrganization) {
                this.quoteMessage = this.organizationWorkshopMessage;
            }

            let result = await quoteService.acceptQuote(
                new QuoteAccept(
                    this.quoteModel.id,
                    user,
                    this.quoteModel.dateModifiedUtc,
                    this.quoteMessage,
                    this.orderNumber,
                ),
                [this.attachment],
            );

            if (result.status === 200) {
                this.trackEvent('AcceptQuote', 'Quote', '');
                this.$router.go(this.$router.currentRoute);
            }
            else {
                this.quoteOutOfDateDialog = _.has(
                    result.response.data,
                    'DateModifiedUtc',
                );
            }

            this.quoteAcceptLoading = false;
        },
        async acceptAmendmentConfirmed() {
            this.quoteAcceptLoading = true;

            if (this.isOrganization) {
                this.quoteMessage = this.organizationWorkshopMessage;
            }

            let result = await quoteService.accpetQuoteAmendment(
                new QuoteAmendmentAccept(
                    this.quoteModel.id,
                    this.getAmendments().approved,
                    this.getAmendments().declined,
                    this.quoteMessage,
                    this.orderNumber,
                ),
                [this.attachment],
            );

            if (result.status === 200) {
                this.trackEvent('AcceptAmendment', 'Quote', '');
                this.$router.go(this.$router.currentRoute);
            }
        },

        hasPendingAmendment(quoteRequestItem) {
            return _.some(quoteRequestItem.quoteItems, {
                amendmentStatus: this.amendmentStatuses.pending,
            });
        },
        quoteHasPendingAmendment() {
            return _.some(this.quoteModel.quoteItems, {
                amendmentStatus: this.amendmentStatuses.pending,
            });
        },
        reloadPage() {
            this.$router.go(this.$router.currentRoute);
        },
        addAttachment() {
            if (!this.attachments) {
                this.attachments = [];
            }
            let index = 1;
            if (this.attachments.length > 0) {
                index = _.last(this.attachments).index;
                index++;
            }
            this.attachments.push({
                index,
                file: undefined,
            });
        },
        deleteAttachment(attachment) {
            let index = this.attachments.findIndex(
                att => att.index === attachment.index,
            );
            this.attachments.splice(index, 1);
        },
        async openQuoteViewer(override) {
            if (this.quoteRequestOrganizationShareProp === null) {
                window.open(this.shareLink, '_blank');
            }
            else if (
                this.quoteRequestOrganizationShareProp.quoteId !== this.quoteModel.id
            ) {
                if (override) {
                    window.open(this.shareLink, '_blank');
                    this.quoteRequestDifferentShareDialog = false;
                }
                else {
                    this.quoteRequestDifferentShareDialog = true;
                }
            }
            else {
                window.open(this.shareLink, '_blank');
            }
        },
        async copyQuoteViewer(override) {
            if (this.quoteRequestOrganizationShareProp === null) {
                this.$copyText(this.shareLink);
                this.copySnackbar = true;
            }
            else if (
                this.quoteRequestOrganizationShareProp.quoteId !== this.quoteModel.id
            ) {
                if (override) {
                    this.$copyText(this.shareLink);
                    this.copySnackbar = true;
                    this.quoteRequestDifferentShareDialog = false;
                }
                else {
                    this.quoteRequestDifferentShareDialog = true;
                }
            }
            else {
                this.$copyText(this.shareLink);
                this.copySnackbar = true;
            }
        },
        openNexusDialog() {
            this.submitNexusError = false;
            this.submitNexusDialog = true;
        },
        async submitNexus() {
            if (await this.shareOrganizationBranchQuote()) {
                this.sharedSnackbar = true;
                this.submitNexusDialog = false;
            }
        },
        async shareOrganizationBranchQuote() {
            this.shareLoading = true;
            if (!this.isOrganization) {
                return true;
            }
            let result = await quoteRequestService.shareOrganizationBranchQuote(
                this.quoteRequestProp.id,
                this.quoteModel.id,
            );

            if (result.status === 200) {
                this.$emit('updateOrganizationShare', this.quoteModel);
            }
            else {
                this.submitNexusError = true;
            }
            this.shareLoading = false;
            return result.status === 200;
        },
    },
};
</script>

<style lang="scss" scoped>
.smc-card-dialog.v-card > .v-card__subtitle.smc-card-dialog-subtitle-inset {
  display: inline-flex;
  height: 68px;
}

.smc-card-dialog.v-card .smc-card-dialog-mid-heading {
  max-width: 265px;

  @media screen and (max-width: 960px) {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    padding: 15px 0;
  }
}

.smc-card-dialog.v-card > .v-card__title.smc-card-dialog-title-inset {
  @media screen and (max-width: 670px) {
    width: 100%;
    border-bottom-right-radius: 0;
    border-top-right-radius: 30px;
  }
}

.smc-card-dialog.v-card > .v-card__subtitle.smc-card-dialog-subtitle-inset {
  @media screen and (max-width: 670px) {
    width: 100%;
    position: relative;
    border-bottom-right-radius: 32px;
  }
}

.smc-card-avatar {
  @media screen and (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.quote-total {
  width: 100%;
}

.certify-badge {
  @media screen and (max-width: 670px) {
    flex: initial;
  }
}

.posh-badge {
  @media screen and (max-width: 670px) {
    flex: initial;
  }
}

.smc-card-avatar a img {
  padding: 5px;
}

.v-expansion-panel.rounded-bottom,
.v-expansion-panel-header.rounded-bottom:not(
    .v-expansion-panel-header--active
  ) {
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

.organization-approval-card-success {
  border: 2px solid #4caf50 !important;
}

.organization-approval-card-error {
  border: 2px solid #ed3237 !important;
}
</style>

<style>
.order-tab-container,
.order-tab-container .v-tabs {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
}

.order-tab-container .v-slide-group {
  height: 60px;
  padding: 10px;
}

.order-tab-container .v-tab {
  border-radius: 15px;
  border: 1px solid #7a191c;
  margin: 0 8px;
}

.order-tab-container .v-tabs-slider {
  color: transparent !important;
}
</style>

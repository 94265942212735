import _ from 'lodash';
import { pdfService } from '@/services';
import 'jspdf-autotable';
import {
    addressHelper,
    dateHelper,
    financeHelper,
    amendmentTypes,
    amendmentStatuses,
    quoteStatuses,
} from '@/helpers';

export default {
    data: () => ({
        doc: undefined,
        isInitialized: false,
        showImageCentering: false,
        topMargin: 15,
        leftMargin: 10,
        rightMargin: 200,
        footerBoxHeight: 33,
        detailsHeadStyle: {
            fontSize: 9,
            fillColor: '#ffffff',
            textColor: '#000000',
            cellPadding: 0.5,
            font: 'Roboto-Bold',
        },
        detailsTableStyle: {
            fontSize: 8,
            fillColor: '#ffffff',
            textColor: '#000000',
            cellPadding: 0.5,
            font: 'Roboto-Regular',
            cellWidth: 'auto',
        },
        quoteRequestHeaderStyle: {
            font: 'Roboto-Bold',
            fillColor: '#eaeaea',
            textColor: '#1f1f1f',
            cellPadding: 1,
            fontSize: 8,
        },
        quoteTableFooterStyle: {
            font: 'Roboto-Bold',
            halign: 'right',
            cellPadding: 1,
            cellWidth: 30,
            fontSize: 8,
        },
        quoteTableAltFooterStyle: {
            font: 'Roboto-Bold',
            halign: 'right',
            fillColor: '#eaeaea',
            textColor: '#1f1f1f',
            cellPadding: 1,
            cellWidth: 30,
            fontSize: 8,
        },
        descriptionStyle: {
            font: 'Roboto-Regular',
            cellWidth: 'auto',
            halign: 'left',
            cellPadding: 1,
            fontSize: 8,
        },
        unitPriceStyle: {
            font: 'Roboto-Regular',
            cellWidth: 20,
            halign: 'right',
            cellPadding: 1,
            fontSize: 8,
        },
        quantityStyle: {
            font: 'Roboto-Regular',
            cellWidth: 20,
            halign: 'right',
            cellPadding: 1,
            fontSize: 8,
        },
        totalStyle: {
            font: 'Roboto-Regular',
            cellWidth: 25,
            halign: 'right',
            cellPadding: 1,
            fontSize: 8,
        },
        additionalNotesStyle: {
            font: 'Roboto-Italic',
            textColor: '#1f1f1f',
            cellPadding: 1.5,
            fontSize: 8,
        },
        termsStyle: {
            font: 'Roboto-Regular',
            fillColor: '#eaeaea',
            textColor: '#1f1f1f',
            cellPadding: 0.5,
            fontSize: 6,
        },
    }),
    computed: {
        usableWitdh() {
            if (this.doc === undefined) {
                return 0;
            }
            return this.doc.internal.pageSize.width - 2 * this.leftMargin;
        },
        usableHeight() {
            if (this.doc === undefined) {
                return 0;
            }
            return this.doc.internal.pageSize.height - 2 * this.topMargin;
        },
        footerBoxBottom() {
            return this.usableHeight - this.footerBoxHeight + this.topMargin;
        },
        pageNumberOffset() {
            return this.doc.internal.pageSize.height - (this.topMargin - 4);
        },
    },
    created() {
        this.doc = pdfService.initializeDocument();
    },
    methods: {
        //#region helper-methods
        quoteInvoiceHeader(quote) {
            let heading = 'QUOTATION';
            if (quote.quoteStatus === quoteStatuses.proForma.id) {
                heading = 'DRAFT INVOICE';
            }
            else if (
                quote.quoteStatus === quoteStatuses.invoiced.id
                || quote.quoteStatus === quoteStatuses.completed.id
                || quote.quoteStatus === quoteStatuses.paid.id
            ) {
                heading = 'TAX INVOICE';
            }

            return heading;
        },

        tableTitleStyle(align) {
            return {
                fillColor: '#eaeaea',
                textColor: '#1f1f1f',
                lineColor: '#9f9f9f',
                lineWidth: 0.1,
                halign: align,
                cellPadding: 1,
                font: 'Roboto-Bold',
            };
        },

        tableBodyStyle(align) {
            return {
                fillColor: '#ffffff',
                textColor: '#1f1f1f',
                lineColor: '#9f9f9f',
                lineWidth: 0.1,
                halign: align,
                cellPadding: 1,
                font: 'Roboto-Regular',
            };
        },

        getQuoteRequestItemTotal(quoteRequestItem, excl) {
            let sum = 0;
            if (excl) {
                sum = _.sumBy(quoteRequestItem.quoteItems, function (item) {
                    let total = 0;
                    if (!item.verifiedTotalExcl) {
                        total = item.quantity * item.priceExcl;
                    }
                    else {
                        total = item.verifiedTotalExcl;
                    }

                    return total;
                });

                if (!quoteRequestItem.labourItem.verifiedTotalExcl) {
                    sum += quoteRequestItem.labourItem.quantity * quoteRequestItem.labourItem.priceExcl;
                }
                else {
                    sum += quoteRequestItem.labourItem.verifiedTotalExcl;
                }
            }
            else {
                sum = _.sumBy(quoteRequestItem.quoteItems, function (item) {
                    let total = 0;
                    if (!item.verifiedTotalIncl) {
                        total = item.quantity * item.priceIncl;
                    }
                    else {
                        total = item.verifiedTotalIncl;
                    }

                    return total;
                });

                if (!quoteRequestItem.labourItem.verifiedTotalIncl) {
                    sum += quoteRequestItem.labourItem.quantity * quoteRequestItem.labourItem.priceIncl;
                }
                else {
                    sum += quoteRequestItem.labourItem.verifiedTotalIncl;
                }
            }
            return sum;
        },

        getQuoteTotal(quote) {
            let total = 0;
            quote.quoteRequestItems.forEach(element => {
                if (element.quoteItems.length > 0) {
                    total += this.getQuoteRequestItemTotal(element);
                }
            });
            total += quote.consumablesItem.priceIncl;
            return total;
        },

        totalVat(quote) {
            return financeHelper.determineVat(this.getQuoteTotal(quote));
        },

        getDataUri(url) {
            return new Promise(resolve => {
                let image = new Image();
                image.setAttribute('crossOrigin', 'anonymous'); //getting images from external domain

                image.onload = function () {
                    let canvas = document.createElement('canvas');
                    canvas.width = this.naturalWidth;
                    canvas.height = this.naturalHeight;

                    //next three lines for white background in case png has a transparent background
                    let ctx = canvas.getContext('2d');
                    ctx.fillStyle = '#fff'; /// set white fill style
                    ctx.fillRect(0, 0, canvas.width, canvas.height);

                    canvas.getContext('2d').drawImage(this, 0, 0);

                    resolve(canvas.toDataURL('image/jpeg'));
                };

                image.src = `${url}?not-from-cache-please=true`;
            });
        },

        hasAnyDiscounts(quote) {
            let anyDiscount = false;
            for (let i = 0; i < quote.quoteRequestItems.length && !anyDiscount; i++) {
                anyDiscount = _.some(quote.quoteRequestItems[i].quoteItems, function (
                    itm,
                ) {
                    return itm.discountPerc && itm.discountPerc > 0;
                });
            }
            return anyDiscount;
        },
        //#endregion

        async downloadQuotePdf(quote, additionalReferences, vatMode) {
            if (quote) {
                await this.generateQuotePdfDocument(
                    quote,
                    additionalReferences,
                    vatMode,
                );
                if (quote.quoteStatus === quoteStatuses.proForma.id) {
                    this.addWatermark('DRAFT INVOICE');
                }
                this.doc.save(`${quote.smcReference}.pdf`);
            }
        },

        async downloadQuotePreviewPdf(quote, additionalReferences, vatMode) {
            if (quote) {
                await this.generateQuotePdfDocument(
                    quote,
                    additionalReferences,
                    vatMode,
                );
                this.addWatermark('QUOTE NOT FINALIZED');
                this.doc.save('preview.pdf');
            }
        },

        async printQuotePdfString(quote, additionalReferences, vatMode) {
            if (quote) {
                await this.generateQuotePdfDocument(
                    quote,
                    additionalReferences,
                    vatMode,
                );
                return this.doc.output('datauristring');
            }
        },

        async generateQuotePdfDocument(
            quote,
            additionalReferences,
            vatMode,
        ) {
            let customer = quote.workshopCustomer;
            if (this.isInitialized) {
                this.doc = pdfService.initializeDocument();
            }

            this.doc.setFont('Roboto-Regular', 'normal');

            this.addQuoteWorkshopSection(quote);
            await this.addQuoteWorkshopLogo(quote);

            let customerInfoY = this.addCustomerInfoTable(quote, customer);
            let referencesY = this.addQuoteReferencesTable(
                quote,
                additionalReferences,
                customer,
            );

            let maxY = _.max([customerInfoY, referencesY]);
            this.addHeadingLines(maxY);

            let y = this.addQuoteRequestItems(quote, vatMode, maxY + 5);
            if (quote.additionalItems && quote.additionalItems.length > 0) {
                y = this.addAdditionalItems(quote, y);
            }
            y = this.addQuoteTableFooter(quote, vatMode, y);
            if (quote.quoteMessages) {
                y = this.addQuoteMessages(quote, y + 5);
            }
            y = await this.addQuoteFooterCertifications(quote, y);
            y = this.addQuoteFooter(quote, y);
            //y = this.addWorkshopTerms(y + 5);
            this.addPageNumbers();

            this.isInitialized = true;
            console.log('y: ', y);
        },

        addQuoteWorkshopSection(quote) {
            this.doc.setFont('Roboto-Bold', 'normal');
            this.doc.setFontSize(9);
            this.doc.setLineHeightFactor(1.5);
            this.doc.text(quote.workshop.name, this.leftMargin, this.topMargin);

            let workshopInfoArray = [];
            if (quote.workshop.address) {
                workshopInfoArray.push(
                    ...addressHelper.arrayiphyAddressBrief(quote.workshop.address),
                );
            }
            else {
                workshopInfoArray.push[('', '', '', '')];
            }

            if (
                quote.workshop.workshopContact
                && quote.workshop.workshopContact.phone
            ) {
                workshopInfoArray.push(
                    `Phone: ${quote.workshop.workshopContact.phone}`,
                );
            }

            if (
                quote.workshop.workshopContact
                && quote.workshop.workshopContact.email
            ) {
                workshopInfoArray.push(
                    `Email: ${quote.workshop.workshopContact.email}`,
                );
            }

            this.doc.setFont('Roboto-Regular', 'normal');
            this.doc.setFontSize(8);
            this.doc.text(workshopInfoArray, this.leftMargin + 2, this.topMargin + 5);

            let lineRightOffset = (this.usableWitdh / 3) * 2 + this.leftMargin;
            this.doc.line(
                this.leftMargin,
                this.topMargin + 29,
                lineRightOffset,
                this.topMargin + 29,
            );
        },

        async addQuoteWorkshopLogo(quote) {
            if (quote.workshop.logoUrl) {
                try {
                    const maxWidth = 40;
                    const maxHeight = 30;

                    let imageData = await this.getDataUri(quote.workshop.logoUrl);
                    let imageProps = this.doc.getImageProperties(imageData);

                    let imageWidth = imageProps.width;
                    let imageHeight = imageProps.height;

                    let sizeRatio = 0;
                    if (imageWidth > imageHeight) {
                        sizeRatio = imageWidth / maxWidth;
                    }
                    else {
                        sizeRatio = imageHeight / maxHeight;
                    }

                    let newWidth = imageWidth / sizeRatio;
                    let newHeight = imageHeight / sizeRatio;

                    // ensure logo isn't an aspect ratio that could cause the logo to still go over the non-applied dimension
                    if (newWidth > newHeight && newHeight > maxHeight) {
                        sizeRatio = newHeight / maxHeight;

                        newWidth = newWidth / sizeRatio;
                        newHeight = newHeight / sizeRatio;
                    }
                    else if (newHeight > newWidth && newWidth > maxWidth) {
                        sizeRatio = newWidth / maxWidth;

                        newWidth = newWidth / sizeRatio;
                        newHeight = newHeight / sizeRatio;
                    }

                    let centerX = this.doc.internal.pageSize.width / 2;
                    let leftOffset = centerX - newWidth / 2;

                    this.doc.addImage(
                        imageData,
                        imageProps.fileType,
                        leftOffset,
                        this.topMargin - 3,
                        newWidth,
                        newHeight,
                    );
                }
                catch (error) {
                    // something wrong with the logo - it simply won't appear on the PDF.
                    console.error(error);
                }
            }
        },

        addCustomerInfoTable(quote, customer) {
            this.doc.setFont('Roboto-Bold', 'normal');
            this.doc.setFontSize(9);
            this.doc.text(
                'Customer Information',
                this.leftMargin,
                this.topMargin + 33,
            );

            let customerInfoList = [];

            let customerNames = [];
            if (customer.firstName) {
                customerNames.push(customer.firstName);
            }
            if (customer.lastName) {
                customerNames.push(customer.lastName);
            }

            if (quote.vehicleMapping.vehicleName) {
                customerInfoList.push([
                    ' ',
                    { content: ' ', styles: { cellWidth: 'auto' } },
                    'Vehicle:',
                    {
                        content: quote.vehicleMapping.vehicleName,
                        styles: { cellWidth: 'auto' },
                    },
                ]);
            }

            customerInfoList.push([
                'Name:',
                { content: _.join(customerNames, ' '), styles: { cellWidth: 'auto' } },
                'Vehicle Model:',
                {
                    content: quote.vehicleMapping.vehicle ? `${quote.vehicleMapping.vehicle.brand} ${quote.vehicleMapping.vehicle.range}` : '',
                    styles: { cellWidth: 'auto' },
                },
            ]);
            customerInfoList.push([
                'Account Number:',
                {
                    content: customer.accountNumber ? customer.accountNumber : '',
                    styles: { cellWidth: 'auto' },
                },
                'Vehicle License:',
                {
                    content: quote.vehicleMapping.vehicleLicensePlate
                        ? quote.vehicleMapping.vehicleLicensePlate
                        : '',
                    styles: { cellWidth: 'auto' },
                },
            ]);
            customerInfoList.push([
                'Customer Code:',
                {
                    content: customer.code ? customer.code : '',
                    styles: { cellWidth: 'auto' },
                },
                'Vehicle VIN:',
                {
                    content: quote.vehicleMapping.vehicleVinNumber ? quote.vehicleMapping.vehicleVinNumber : '',
                    styles: { cellWidth: 'auto' },
                },
            ]);
            customerInfoList.push([
                'Email:',
                {
                    content: customer.email
                        ? customer.email
                        : '',
                    styles: { cellWidth: 'auto' },
                },
                'Vehicle Engine No:',
                {
                    content: quote.vehicleMapping.vehicleEngineNumber ? quote.vehicleMapping.vehicleEngineNumber : '',
                    styles: { cellWidth: 'auto' },
                },
            ]);
            customerInfoList.push([
                'Phone:',
                {
                    content: customer.phone
                        ? customer.phone
                        : '',
                    styles: { cellWidth: 'auto' },
                },
                'Vehicle Mileage:',
                {
                    content: quote.vehicleMileage
                        ? `${quote.vehicleMileage.toLocaleString('en-ZA')} km`
                        : '',
                    styles: { cellWidth: 'auto' },
                },
            ]);

            this.doc.autoTable({
                startY: this.topMargin + 35,
                tableWidth: (this.usableWitdh / 3) * 2,
                margin: { left: this.leftMargin + 1 },
                bodyStyles: this.detailsTableStyle,
                alternateRowStyles: this.detailsTableStyle,
                body: customerInfoList,
            });

            return this.doc.lastAutoTable.finalY;
        },

        addQuoteReferencesTable(quote, additionalReferences, customer) {
            let thirdWidth = this.usableWitdh / 3;
            let leftOffset = thirdWidth * 2 + this.leftMargin;

            let headRow = [];
            headRow.push([{
                content: this.quoteInvoiceHeader(quote),
                colSpan: 2,
            }]);

            let referencesInfo = [];

            if (quote.invoiceNumber) {
                referencesInfo.push([
                    'Reference',
                    {
                        content: quote.smcReference,
                        styles: { cellWidth: 'auto' },
                    },
                ]);
            }

            let dateLabel = 'Service Date:';
            let dateValue = quote.serviceDate;

            referencesInfo.push([
                dateLabel,
                {
                    content: dateHelper.formatDateShort(dateValue),
                    styles: { cellWidth: 'auto' },
                },
            ]);

            if (
                quote.quoteStatus === quoteStatuses.proForma.id
                || quote.quoteStatus === quoteStatuses.invoiced.id
                || quote.quoteStatus === quoteStatuses.completed.id
                || quote.quoteStatus === quoteStatuses.paid.id
            ) {
                let dueDateLabel = 'Due Date:';
                let dueDateValue = quote.dueDate;
                if (_.startsWith(quote.dueDate, '0001')) {
                    dueDateValue = new Date();
                }

                referencesInfo.push([
                    dueDateLabel,
                    {
                        content: dateHelper.formatDateShort(dueDateValue),
                        styles: { cellWidth: 'auto' },
                    },
                ]);
            }

            if (quote.externalReference) {
                referencesInfo.push([
                    'Reference:',
                    { content: quote.externalReference, styles: { cellWidth: 'auto' } },
                ]);
            }

            if (quote.quoteAuthorizationCodes) {
                for (let i = 0; i < quote.quoteAuthorizationCodes.length; i++) {
                    referencesInfo.push([
                        i === 0 ? 'Authorization:' : '',
                        { content: quote.quoteAuthorizationCodes[i].authorizationCode, styles: { cellWidth: 'auto' } },
                    ]);
                }
            }

            if (additionalReferences) {
                additionalReferences.forEach(ref => {
                    if (ref.label && ref.value) {
                        referencesInfo.push([
                            {
                                content: `${ref.label}:`,
                            },
                            {
                                content: ref.value,
                            },
                        ]);
                    }
                });
            }

            if (customer && customer.businessName) {
                referencesInfo.push([
                    {
                        content: customer.businessName,
                        lineTop: true,
                        colSpan: 2,
                        styles: {
                            cellWidth: 'auto',
                            font: 'Roboto-Bold',
                            cellPadding: { top: 2 },
                        },
                    },
                ]);

                if (customer.businessTaxNumber) {
                    referencesInfo.push([
                        'VAT/Tax:',
                        {
                            content: customer.businessTaxNumber,
                            styles: {
                                cellWidth: 'auto',
                            },
                        },
                    ]);
                }

                if (customer.businessRegistrationNumber) {
                    referencesInfo.push([
                        'Reg no:',
                        {
                            content: customer.businessRegistrationNumber,
                            styles: {
                                cellWidth: 'auto',
                            },
                        },
                    ]);
                }

                if (customer.businessPhone) {
                    referencesInfo.push([
                        'Phone:',
                        {
                            content: customer.businessPhone,
                            styles: {
                                cellWidth: 'auto',
                            },
                        },
                    ]);
                }

                if (customer.address && (customer.address.suburb !== '' || customer.address.city !== '')) {
                    referencesInfo.push([{ content: 'Address:' }, '']);
                    referencesInfo.push([
                        {
                            content: _.join(
                                [
                                    `${customer.address.streetNumber} ${customer.address.streetName}`,
                                    customer.address.suburb,
                                    customer.address.city,
                                    customer.address.postalCode,
                                ],
                                ', ',
                            ),
                            colSpan: 2,
                            styles: {
                                cellWidth: 'auto',
                            },
                        },
                    ]);
                }
            }

            this.doc.autoTable({
                startY: this.topMargin - 3,
                tableWidth: thirdWidth - 4,
                margin: { left: leftOffset + 2 },
                headStyles: this.detailsHeadStyle,
                bodyStyles: this.detailsTableStyle,
                alternateRowStyles: this.detailsTableStyle,
                head: headRow,
                body: referencesInfo,
                didDrawCell: hookData => {
                    if (hookData.cell.raw && hookData.cell.raw.lineTop) {
                        hookData.doc.setDrawColor('#000000');

                        hookData.doc.line(
                            hookData.cursor.x - 2,
                            hookData.cursor.y + 1,
                            hookData.cursor.x + hookData.cell.width + 2,
                            hookData.cursor.y + 1,
                        );
                    }
                },
            });

            if (quote.invoiceNumber) {
                this.doc.text(
                    quote.invoiceNumber,
                    this.rightMargin - 2,
                    this.topMargin,
                    'right',
                );
            }
            else {
                this.doc.text(
                    quote.smcReference,
                    this.rightMargin - 2,
                    this.topMargin,
                    'right',
                );
            }

            return this.doc.lastAutoTable.finalY;
        },

        addHeadingLines(maxY) {
            let thirdWidth = this.usableWitdh / 3;
            let leftOffset = thirdWidth * 2 + this.leftMargin;

            this.doc.setDrawColor('#000000');

            this.doc.line(leftOffset, this.topMargin - 3, leftOffset, maxY + 2);
            this.doc.line(
                this.rightMargin,
                this.topMargin - 3,
                this.rightMargin,
                maxY + 2,
            );
            //this.doc.line(this.leftMargin, maxY + 2, this.rightMargin, maxY + 2);
        },

        addQuoteRequestItems(quote, vatMode, y) {
            let vm = this;
            let lineItems = [];
            let anyDiscount = this.hasAnyDiscounts(quote);

            quote.quoteRequestItems.forEach(item => {
                let quoteItems = [];

                item.quoteItems.forEach(quoteItem => {
                    if (
                        quoteItem.amendmentType === null
                        || (quoteItem.amendmentType !== amendmentTypes.removed && quoteItem.amendmentStatus === amendmentStatuses.approved)
                    ) {
                        let cols = [];
                        cols.push({
                            content: quoteItem.description,
                            styles: this.descriptionStyle,
                        });
                        if (vatMode === 'No VAT') {
                            cols.push({
                                content: financeHelper.formatNumberAsCurrency(
                                    quoteItem.priceExcl,
                                ),
                                styles: this.unitPriceStyle,
                            });
                        }
                        else {
                            cols.push({
                                content: financeHelper.formatNumberAsCurrency(
                                    quoteItem.priceIncl,
                                ),
                                styles: this.unitPriceStyle,
                            });
                        }
                        cols.push({
                            content: quoteItem.quantity,
                            styles: this.quantityStyle,
                        });

                        if (anyDiscount) {
                            cols.push({
                                content: `${quoteItem.discountPerc} %`,
                                styles: this.quantityStyle,
                            });
                        }

                        if (!quoteItem.verifiedTotalExcl) {
                            quoteItem.verifiedTotalExcl = quoteItem.quantity * quoteItem.priceExcl;
                        }
                        if (!quoteItem.verifiedTotalIncl) {
                            quoteItem.verifiedTotalIncl = quoteItem.quantity * quoteItem.priceIncl;
                        }
                        let total = quoteItem.verifiedTotalIncl;
                        if (vatMode === 'No VAT') {
                            total = quoteItem.verifiedTotalExcl;
                        }
                        cols.push({
                            content: financeHelper.formatNumberAsCurrency(
                                total,
                            ),
                            styles: this.totalStyle,
                        });

                        quoteItems.push(cols);
                    }
                });

                if (quoteItems.length > 0) {
                    if (item.labourItem && item.labourItem.amendmentType !== amendmentTypes.removed) {
                        if (
                            item.labourItem.amendmentStatus === null
                            || item.labourItem.amendmentStatus === amendmentStatuses.approved
                        ) {
                            let labourPrice = 0;
                            let labourTotal = 0;
                            let labourCols = [];
                            labourCols.push({
                                content: item.labourItem.description,
                                styles: this.descriptionStyle,
                            });

                            if (vatMode === 'No VAT') {
                                labourPrice = item.labourItem.priceExcl;
                            }
                            labourCols.push({
                                content: financeHelper.formatNumberAsCurrency(labourPrice),
                                styles: this.unitPriceStyle,
                            });
                            labourCols.push({
                                content: item.labourItem.quantity,
                                styles: this.quantityStyle,
                            });

                            if (anyDiscount) {
                                labourCols.push({
                                    content: `${item.labourItem.discountPerc} %`,
                                    styles: this.quantityStyle,
                                });
                            }

                            if (vatMode === 'No VAT') {
                                if (!item.labourItem.verifiedTotalExcl) {
                                    labourTotal = item.labourItem.quantity * item.labourItem.priceExcl;
                                }
                                else {
                                    labourTotal = item.labourItem.verifiedTotalExcl;
                                }
                            }
                            else {
                                if (!item.labourItem.verifiedTotalIncl) {
                                    labourTotal = item.labourItem.quantity * item.labourItem.priceIncl;
                                }
                                else {
                                    labourTotal = item.labourItem.verifiedTotalIncl;
                                }
                            }

                            labourCols.push({
                                content: financeHelper.formatNumberAsCurrency(labourTotal),
                                styles: this.totalStyle,
                            });

                            quoteItems.push(labourCols);
                        }
                    }

                    lineItems.push([
                        {
                            content: '',
                            quoteRequestItem: item,
                            colSpan: anyDiscount ? 5 : 4,
                            styles: this.quoteRequestHeaderStyle,
                        },
                    ]);

                    if (item.quoteRequestItemNotes && item.quoteRequestItemNotes.length > 0) {
                        item.quoteRequestItemNotes.forEach(qrin => {
                            lineItems.push([{
                                content: qrin.note,
                                colSpan: anyDiscount ? 5 : 4,
                                styles: { ...this.descriptionStyle, halign: 'center' },
                            }]);
                        });
                    }

                    lineItems.push(...quoteItems);
                }
            });

            let headRow = [];
            headRow.push({
                content: 'Description',
                styles: this.descriptionStyle,
            });
            headRow.push({
                content: 'Unit Price',
                styles: this.unitPriceStyle,
            });
            headRow.push({
                content: 'Quantity',
                styles: this.quantityStyle,
            });

            if (anyDiscount) {
                headRow.push({
                    content: 'Discount',
                    styles: this.quantityStyle,
                });
            }

            headRow.push({
                content: 'Total',
                styles: this.totalStyle,
            });

            this.doc.autoTable({
                startY: y,
                theme: 'plain',
                rowPageBreak: 'avoid',
                margin: { left: this.leftMargin },
                tableWidth: this.usableWitdh,
                head: [headRow],
                body: lineItems,
                didDrawCell: hookData => {
                    if (hookData.cell.raw.quoteRequestItem) {
                        hookData.doc.setFont('Roboto-Regular', 'normal');
                        hookData.doc.text(
                            hookData.cell.raw.quoteRequestItem.name,
                            this.leftMargin + 1.0,
                            hookData.cursor.y + hookData.cell.height - 1.5,
                        );

                        let total = 0;
                        if (vatMode === 'No VAT') {
                            total = financeHelper.formatNumberAsCurrency(
                                vm.getQuoteRequestItemTotal(hookData.cell.raw.quoteRequestItem, true),
                            );
                        }
                        else {
                            total = financeHelper.formatNumberAsCurrency(
                                vm.getQuoteRequestItemTotal(hookData.cell.raw.quoteRequestItem, false),
                            );
                        }

                        hookData.doc.text(
                            `${total}`,
                            hookData.cursor.x + hookData.cell.width - 1.0,
                            hookData.cursor.y + hookData.cell.height - 1.5,
                            'right',
                        );

                        hookData.doc.setDrawColor('#000000');
                        hookData.doc.line(
                            hookData.cursor.x,
                            hookData.cursor.y,
                            hookData.cursor.x + hookData.cell.width,
                            hookData.cursor.y,
                        );

                        hookData.doc.line(
                            hookData.cursor.x,
                            hookData.cursor.y,
                            hookData.cursor.x,
                            hookData.cursor.y + hookData.cell.height,
                        );

                        hookData.doc.line(
                            hookData.cursor.x + hookData.cell.width,
                            hookData.cursor.y,
                            hookData.cursor.x + hookData.cell.width,
                            hookData.cursor.y + hookData.cell.height,
                        );
                    }

                    // manually draw bottom borders for table rows
                    hookData.doc.line(
                        hookData.cursor.x,
                        hookData.cursor.y + hookData.cell.height,
                        hookData.cursor.x + hookData.cell.width,
                        hookData.cursor.y + hookData.cell.height,
                    );
                },
            });

            return this.doc.lastAutoTable.finalY;
        },

        addAdditionalItems(quote, y) {
            let lineItems = [];
            let anyDiscount = this.hasAnyDiscounts(quote);

            lineItems.push([
                {
                    content: '',
                    quoteRequestItem: { name: 'Additional complimentary' },
                    colSpan: anyDiscount ? 5 : 4,
                    styles: this.quoteRequestHeaderStyle,
                },
            ]);

            quote.additionalItems.forEach(item => {
                lineItems.push([
                    {
                        content: item.description,
                        styles: this.descriptionStyle,
                    },
                ]);
            });

            this.doc.autoTable({
                startY: y,
                theme: 'plain',
                rowPageBreak: 'avoid',
                margin: { left: this.leftMargin },
                tableWidth: this.usableWitdh,
                body: lineItems,
                didDrawCell: hookData => {
                    if (
                        (anyDiscount && hookData.cell.colSpan === 5)
                        || hookData.cell.colSpan === 4
                    ) {
                        hookData.doc.setFont('Roboto-Regular', 'normal');
                        hookData.doc.text(
                            hookData.cell.raw.quoteRequestItem.name,
                            this.leftMargin + 1.0,
                            hookData.cursor.y + hookData.cell.height - 1.5,
                        );

                        hookData.doc.setDrawColor('#000000');
                        hookData.doc.line(
                            hookData.cursor.x,
                            hookData.cursor.y,
                            hookData.cursor.x + hookData.cell.width,
                            hookData.cursor.y,
                        );

                        hookData.doc.line(
                            hookData.cursor.x,
                            hookData.cursor.y,
                            hookData.cursor.x,
                            hookData.cursor.y + hookData.cell.height,
                        );

                        hookData.doc.line(
                            hookData.cursor.x + hookData.cell.width,
                            hookData.cursor.y,
                            hookData.cursor.x + hookData.cell.width,
                            hookData.cursor.y + hookData.cell.height,
                        );
                    }

                    // manually draw bottom borders for table rows
                    hookData.doc.line(
                        hookData.cursor.x,
                        hookData.cursor.y + hookData.cell.height,
                        hookData.cursor.x + hookData.cell.width,
                        hookData.cursor.y + hookData.cell.height,
                    );
                },
            });

            return this.doc.lastAutoTable.finalY;
        },

        addQuoteTableFooter(quote, vatMode, y) {
            let lineItems = [];

            if (quote.consumablesItem && quote.consumablesItem.priceIncl > 0) {
                let consumablesItems = [];

                consumablesItems.push(
                    {
                        content: '',
                        styles: {
                            cellPadding: 1,
                        },
                    },
                    {
                        content: quote.consumablesItem.description,
                        styles: this.quoteTableFooterStyle,
                        colSpan: 2,
                    },
                );

                if (vatMode === 'No VAT') {
                    consumablesItems.push({
                        content: financeHelper.formatNumberAsCurrency(
                            quote.consumablesItem.priceExcl,
                        ),
                        styles: this.totalStyle,
                    });
                }
                else {
                    consumablesItems.push({
                        content: financeHelper.formatNumberAsCurrency(
                            quote.consumablesItem.priceIncl,
                        ),
                        styles: this.totalStyle,
                    });
                }

                lineItems.push(consumablesItems);
            }

            let quoteTotal = this.getQuoteTotal(quote);
            let vatTotal = this.totalVat(quote);

            if (vatMode === 'No VAT') {
                lineItems.push([
                    {
                        content: '',
                        styles: {
                            cellPadding: 1,
                        },
                    },
                    {
                        content: 'Total',
                        styles: this.quoteTableFooterStyle,
                        colSpan: 2,
                    },
                    {
                        content: financeHelper.formatNumberAsCurrency(quoteTotal),
                        styles: this.totalStyle,
                    },
                ]);
            }
            else {
                lineItems.push([
                    {
                        content: '',
                        styles: {
                            cellPadding: 1,
                        },
                    },
                    {
                        content: 'Total (excl)',
                        styles: this.quoteTableFooterStyle,
                        colSpan: 2,
                    },
                    {
                        content: financeHelper.formatNumberAsCurrency(quoteTotal - vatTotal),
                        styles: this.totalStyle,
                    },
                ]);

                lineItems.push(
                    [
                        {
                            content: '',
                            styles: {
                                cellPadding: 1,
                            },
                        },
                        {
                            content: 'VAT',
                            styles: this.quoteTableFooterStyle,
                            colSpan: 2,
                        },
                        {
                            content: financeHelper.formatNumberAsCurrency(vatTotal),
                            styles: this.totalStyle,
                        },
                    ],
                );

                lineItems.push([
                    {
                        content: '',
                        styles: {
                            cellPadding: 1,
                        },
                    },
                    {
                        content: 'Total (incl)',
                        styles: this.quoteTableFooterStyle,
                        colSpan: 2,
                    },
                    {
                        content: financeHelper.formatNumberAsCurrency(quoteTotal),
                        styles: this.totalStyle,
                    },
                ]);
            }

            this.doc.autoTable({
                startY: y,
                theme: 'plain',
                rowPageBreak: 'avoid',
                margin: { left: this.leftMargin },
                tableWidth: this.usableWitdh,
                body: lineItems,
                didDrawCell: hookData => {
                    if (hookData.cell.text[0] !== '') {
                        hookData.doc.line(
                            hookData.cursor.x,
                            hookData.cursor.y + hookData.cell.height,
                            hookData.cursor.x + hookData.cell.width,
                            hookData.cursor.y + hookData.cell.height,
                        );
                    }
                },
            });

            return this.doc.lastAutoTable.finalY;
        },

        addQuoteMessages(quote, y) {
            let messaageLineheight = 4;
            let messaageLinePadding = 2;

            let quoteMessages = [];

            quote.quoteMessages.forEach(message => {
                let messagesRow = [];
                messagesRow.push(
                    {
                        content: `${message.actorSourceText}\n${dateHelper.formatDateShort(message.dateCreated)}`,
                        styles: {
                            font: 'Roboto-Regular',
                            fontSize: 8,
                        },
                    });

                let messageText = message.message ?? '';
                // remove linebrakes from message
                if (messageText) {
                    messageText = _.replace(messageText, /\r\n/g, ' ');
                    messageText = _.replace(messageText, /\n/g, ' ');
                    messageText = _.replace(messageText, /\r/g, ' ');
                }

                let attachmentsCount = message.attachmentNames.length;
                if (attachmentsCount > 0) {
                    messageText = `${messageText}\n(${attachmentsCount} files attached)`;
                }

                messagesRow.push({
                    content: messageText,
                    styles: {
                        fontSize: 8,
                        overflow: 'linebreak',
                    },
                });

                quoteMessages.push(messagesRow);
            });

            this.doc.autoTable({
                startY: y,
                theme: 'grid',
                rowPageBreak: 'avoid',
                margin: { left: this.leftMargin },
                tableWidth: this.usableWitdh,
                body: quoteMessages,
                bodyStyles: this.additionalNotesStyle,
                willDrawCell: hookData => {
                    let maxLinesCount = 0;
                    let cellsArray = _.values(hookData.row.cells);
                    cellsArray.forEach(cell => {
                        if (cell.text.length > maxLinesCount) {
                            maxLinesCount = cell.text.length;
                        }
                    });

                    let calculatedRowHeight = (messaageLineheight * maxLinesCount) + messaageLinePadding;
                    hookData.cell.height = calculatedRowHeight;
                },
            });

            return this.doc.lastAutoTable.finalY;
        },

        addWorkshopTerms(y) {
            this.doc.autoTable({
                startY: y,
                pageBreak: 'always',
                rowPageBreak: 'avoid',
                margin: { left: this.leftMargin },
                tableWidth: this.usableWitdh,
                html: '#workshop-terms-table',
                bodyStyles: this.termsStyle,
            });

            return this.doc.lastAutoTable.finalY;
        },

        async addQuoteFooterCertifications(quote, y) {
            let certsCount = quote.workshop.workshopCertifications.length;
            let tableBody = [];
            let certsText = undefined;

            if (certsCount > 0) {
                certsText = [];
                for (let cert of quote.workshop.workshopCertifications) {
                    try {
                        let imageData = await this.getDataUri(cert.logoUrl);

                        certsText.push({
                            content: '',
                            imageData: imageData,
                            imageUrl: cert.logoUrl,
                            borderBottom: true,
                        });
                    }
                    catch {
                        console.log('error downloading ', cert.logoUrl);
                    }
                }
            }

            if (certsText) {
                let certTopOffset = this.footerBoxBottom - 20;

                if (y < certTopOffset) {
                    y = certTopOffset;
                }
                else {
                    this.doc.addPage('a4', '1');
                    y = certTopOffset;
                    //y = this.topMargin;
                }

                tableBody.push(certsText);

                this.doc.autoTable({
                    startY: y,
                    theme: 'plain',
                    body: tableBody,
                    tableWidth: this.usableWitdh,
                    margin: { left: this.leftMargin },
                    didDrawCell: hookData => {
                        if (hookData.cell.raw.imageData) {
                            const maxWidth = 20;
                            const maxHeight = 15;

                            let imageProps = this.doc.getImageProperties(
                                hookData.cell.raw.imageData,
                            );

                            let imageWidth = imageProps.width;
                            let imageHeight = imageProps.height;

                            let sizeRatio = 0;
                            if (imageWidth > imageHeight) {
                                sizeRatio = imageWidth / maxWidth;
                            }
                            else {
                                sizeRatio = imageHeight / maxHeight;
                            }

                            let newWidth = imageWidth / sizeRatio;
                            let newHeight = imageHeight / sizeRatio;

                            let centerX = hookData.cell.x + hookData.cell.width / 2;
                            let leftAlign = centerX - newWidth / 2;

                            let centerY = hookData.cell.y + hookData.cell.height / 2;
                            let topAllign = centerY - newHeight / 2;

                            hookData.doc.addImage(
                                hookData.cell.raw.imageData,
                                imageProps.fileType,
                                leftAlign,
                                topAllign,
                                newWidth,
                                newHeight,
                            );

                            //#region Centering Lines
                            if (this.showImageCentering) {
                                hookData.doc.setLineWidth(0.2);

                                hookData.doc.line(
                                    centerX,
                                    hookData.cell.y,
                                    centerX,
                                    hookData.cell.y + hookData.cell.height,
                                );

                                hookData.doc.line(
                                    hookData.cell.x,
                                    centerY,
                                    hookData.cell.x + hookData.cell.width,
                                    centerY,
                                );

                                hookData.doc.line(
                                    hookData.cell.x,
                                    hookData.cell.y,
                                    hookData.cell.x,
                                    hookData.cell.y + hookData.cell.height,
                                );

                                hookData.doc.line(
                                    hookData.cell.x + hookData.cell.width,
                                    hookData.cell.y,
                                    hookData.cell.x + hookData.cell.width,
                                    hookData.cell.y + hookData.cell.height,
                                );

                                hookData.doc.line(
                                    hookData.cell.x,
                                    hookData.cell.y,
                                    hookData.cell.x + hookData.cell.width,
                                    hookData.cell.y + hookData.cell.height,
                                );

                                hookData.doc.line(
                                    hookData.cell.x + hookData.cell.width,
                                    hookData.cell.y,
                                    hookData.cell.x,
                                    hookData.cell.y + hookData.cell.height,
                                );
                            }
                            //#endregion
                        }
                    },
                });

                return this.doc.lastAutoTable.finalY;
            }
            return y;
        },

        addQuoteFooter(quote, y) {
            let boxWidth = this.usableWitdh / 2 - 3;
            let rightBoxOffset = boxWidth + this.leftMargin + 6;

            if (y < this.footerBoxBottom) {
                y = this.footerBoxBottom;
            }
            else {
                this.doc.addPage('a4', '1');
                y = this.footerBoxBottom;
                //y = this.topMargin;
            }

            this.doc.setDrawColor('#000000');
            this.doc.rect(this.leftMargin, y, boxWidth, this.footerBoxHeight);
            this.doc.rect(rightBoxOffset, y, boxWidth, this.footerBoxHeight);

            this.doc.setFont('Roboto-Bold', 'normal');
            this.doc.setFontSize(9);

            this.doc.text(quote.workshop.name, this.leftMargin + 4, y + 5);
            this.doc.setFont('Roboto-Regular', 'normal');
            this.doc.setFontSize(8);

            this.doc.text(
                [
                    `Company Reg No: ${quote.workshop.registrationNumber}`,
                    `Tax/VAT No: ${quote.workshop.taxNumber}`,
                ],
                this.leftMargin + 4,
                y + 10.5,
            );

            if (quote.workshop.bankAccount && quote.workshop.bankAccount.bankName && quote.workshop.bankAccount.accountNumber && quote.workshop.bankAccount.branchCode) {
                this.doc.text(
                    [
                        quote.workshop.bankAccount.bankName,
                        `Account Number: ${quote.workshop.bankAccount.accountNumber}`,
                        `Branch Code: ${quote.workshop.bankAccount.branchCode}`,
                    ],
                    this.leftMargin + 4,
                    y + 21.5,
                );
            }

            this.doc.setFont('Roboto-Regular', 'normal');
            this.doc.setFontSize(8);
            this.doc.text(
                'Please refer to Ts & Cs document.',
                this.rightMargin - 3,
                y + 5,
                'right',
            );

            this.doc.setFont('Roboto-Bold', 'normal');
            this.doc.setFontSize(9);

            let quoteLabel = 'Quotation';
            if (
                quote.quoteStatus === quoteStatuses.proForma.id
                || quote.quoteStatus === quoteStatuses.invoiced.id
                || quote.quoteStatus === quoteStatuses.completed.id
                || quote.quoteStatus === quoteStatuses.paid.id
            ) {
                quoteLabel = 'Invoice';
            }
            this.doc.text(`${quoteLabel} accepted`, rightBoxOffset + 4, y + 5);

            this.doc.setFont('Roboto-Regular', 'normal');
            this.doc.setFontSize(8);

            this.doc.text('Name:', rightBoxOffset + 5, y + 12);
            this.doc.text('Date:', rightBoxOffset + 5, y + 19.5);
            this.doc.text('Signature:', rightBoxOffset + 5, y + 27);

            this.doc.line(
                rightBoxOffset + 20,
                y + 12.5,
                rightBoxOffset + 88,
                y + 12.5,
            );
            this.doc.line(
                rightBoxOffset + 20,
                y + 19.5,
                rightBoxOffset + 88,
                y + 19.5,
            );
            this.doc.line(
                rightBoxOffset + 20,
                y + 27.5,
                rightBoxOffset + 88,
                y + 27.5,
            );

            return y + this.footerBoxHeight;
        },

        addPageNumbers() {
            let totalPages = this.doc.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                this.doc.setPage(i);
                this.doc.text(
                    `Page ${i} of ${totalPages}`,
                    this.rightMargin - 2,
                    this.pageNumberOffset,
                    'right');
            }
        },

        addWatermark(text) {
            let totalPages = this.doc.internal.getNumberOfPages();

            for (let i = 0; i <= totalPages; i++) {
                this.doc.setPage(i);
                this.doc.setFontSize(35);
                this.doc.setTextColor(150);
                this.doc.text(text, 70, 180, null, 45);
            }
        },
    },
};
